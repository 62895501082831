import { getCampaignCountsByType } from 'api'
import axios from 'axios'
import { IntroCampaignsTable } from 'components/IntroDialogList/IntroDialogList'
import { SearchInput } from 'components/SearchInput/SearchInput'
import { isRight } from 'fp-ts/lib/Either'
import { MainstayPage } from 'components/MainstayPageContainer/MainstayPageContainer'
import { MainstaySidebarLinkType } from 'mainstay-ui-kit/MainstaySidebar/MainstaySidebarSectionLink/MainstaySidebarSectionLink'
import { CampaignTable } from 'page/campaign/CampaignTable'
import NavBarPage from 'page/NavBarPage'
import { CreateButton, PageHeader } from 'components/PageHeader/PageHeader'
import * as React from 'react'
import { connect } from 'react-redux'
import { useHistory, useLocation } from 'react-router'
import { RouteComponentProps, withRouter } from 'react-router-dom'
import {
  fetchCampaignCountByType,
  setCampaignHistoryFilterBy,
} from 'store/campaign-history/actions'
import {
  Filters,
  ICampaignBase,
  ICampaignTrigger,
  SortBy,
  SortOrder,
} from 'store/campaign-history/reducer'
import {
  getCampaignList,
  getCampaignTriggers,
  getCampaignsHistoryCountsByType,
  getCampaignSidebarFilter,
  getCurrentCampaignHistoryPage,
  getSortForCampaigns,
} from 'store/campaign-history/selectors'
import {
  fetchCampaignHistoryAsync,
  fetchCampaignTriggersAsync,
} from 'store/campaign-history/thunks'
import { RootState as IState } from 'store/store'
import { useDebounce, useDispatch, useSelector } from 'util/hooks'
import { PERMISSIONS } from 'util/permissions/permissions'
import { HelloPagesCampaignTable } from 'components/HelloPagesCampaignTable/HelloPagesCampaignTable'
import PermissionGuard from 'util/permissions/PermissionGuard'
import {
  appendQueryFilter,
  getQueryFilters,
  removeQueryFilter,
} from 'util/queryFilters'
import {
  FilterButton,
  QUERY_PARAM_DATE_FORMAT,
} from 'components/FilterContactsModal/FilterContactsModal'
import { CampaignHistoryFilterModal } from 'page/campaign/CampaignHistoryFilterModal'
import { Chip } from 'components/Chip/Chip'
import { format, parse } from 'date-fns'
import { WebchatIntroCampaignsTable } from 'components/IntroDialogList/WebchatIntroDialogList'
import { EventAction } from 'components/EventTracker/EventTracker'

const DEBOUNCE_RATE = 500
const clickAction: EventAction = 'click'

export interface ICampaignHistoryPageRouteProps
  extends RouteComponentProps<{ pageNumber: number }> {}

interface ICampaignHistoryOwnProps {
  readonly campaigns: ICampaignBase[]
  readonly triggers: ICampaignTrigger[]
  readonly currentPage: number
  readonly sort: {
    readonly name: SortBy
    readonly order: SortOrder
  }
  readonly campaignSidebarFilter: Filters
}

interface IFilterOption {
  name: string
  filterBy: Filters
}

const filterOptions: IFilterOption[] = [
  {
    name: 'all',
    filterBy: Filters.all,
  },
  {
    name: 'sending',
    filterBy: Filters.sending,
  },
  {
    name: 'sent',
    filterBy: Filters.sent,
  },
  {
    name: 'upcoming',
    filterBy: Filters.upcoming,
  },
  {
    name: 'recurring',
    filterBy: Filters.recurring,
  },
  {
    name: 'is_webchat_intro',
    filterBy: Filters.is_webchat_intro,
  },
  {
    name: 'is_other_intro',
    filterBy: Filters.is_other_intro,
  },
  {
    name: 'is_datatriggered',
    filterBy: Filters.is_datatriggered,
  },
  {
    name: 'is_hellopage',
    filterBy: Filters.is_hellopage,
  },
]

export interface ICounts {
  [key: string]: number
}

function getSidebarLinks(
  filter: string | string[] | undefined,
  counts: ICounts
): MainstaySidebarLinkType[] {
  return [
    {
      text: 'All Scheduled',
      // Since we're navigating to the superset here, we don't need to remove page filtering
      to: '/campaigns',
      active: filter === Filters.all,
      count: counts.totalCount,
      event: {
        location: 'campaigns',
        action: clickAction,
        object: Filters.all,
      },
    },
    ...(counts.sendingCount > 0
      ? [
          {
            text: 'In Progress',
            to: `/campaigns?sidebar_filter=${Filters.sending}`,
            active: filter === Filters.sending,
            count: counts.sendingCount,
            containerClassName: 'ml-2',
            event: {
              location: 'campaigns',
              action: clickAction,
              object: Filters.sending,
            },
          },
        ]
      : []),
    {
      text: 'Sent',
      to: `/campaigns?sidebar_filter=${Filters.sent}`,
      active: filter === Filters.sent,
      count: counts.sentCount,
      containerClassName: 'ml-2',
      event: {
        location: 'campaigns',
        action: clickAction,
        object: Filters.sent,
      },
    },
    {
      text: 'Upcoming',
      to: `/campaigns?sidebar_filter=${Filters.upcoming}`,
      active: filter === Filters.upcoming,
      count: counts.upcomingCount,
      containerClassName: 'ml-2',
      event: {
        location: 'campaigns',
        action: clickAction,
        object: Filters.upcoming,
      },
    },
    {
      text: 'Recurring',
      to: `/campaigns?sidebar_filter=${Filters.recurring}`,
      active: filter === 'recurring',
      count: counts.recurringCount,
      containerClassName: 'ml-2',
      event: {
        location: 'campaigns',
        action: clickAction,
        object: 'recurring',
      },
    },
    {
      text: 'Introductory',
      // 'to' isn't used when disabled=true, but still pass in some value because it's used as a key and needs to be unique
      to: '#intro',
      disabled: true,
      count: counts.totalIntroCount,
      containerClassName: 'mt-4',
    },
    {
      text: 'Webchats',
      to: `/campaigns?sidebar_filter=${Filters.is_webchat_intro}`,
      active: filter === Filters.is_webchat_intro,
      count: counts.webchatIntroCount,
      containerClassName: 'ml-2',
      event: {
        location: 'campaigns',
        action: clickAction,
        object: Filters.is_webchat_intro,
      },
    },
    {
      text: 'Hello Pages',
      to: `/campaigns?sidebar_filter=${Filters.is_hellopage}`,
      active: filter === Filters.is_hellopage,
      count: counts.helloPagesCount,
      containerClassName: 'ml-2',
      event: {
        location: 'campaigns',
        action: clickAction,
        object: 'hello page',
      },
    },
    {
      text: 'Other',
      to: `/campaigns?sidebar_filter=${Filters.is_other_intro}`,
      active: filter === Filters.is_other_intro,
      count: counts.otherIntroCount,
      containerClassName: 'ml-2',
      event: {
        location: 'campaigns',
        action: clickAction,
        object: Filters.is_other_intro,
      },
    },
    {
      text: 'Data-triggered',
      to: `/campaigns?sidebar_filter=${Filters.is_datatriggered}`,
      active: filter === Filters.is_datatriggered,
      count: counts.isDataTriggeredCount,
      containerClassName: 'mt-4',
      event: {
        location: 'campaigns',
        action: clickAction,
        object: 'data triggered',
      },
    },
  ]
}

export const useGetCampaignSidebarLinks = ({
  campaignSidebarFilter,
  query,
  startDate,
  endDate,
}: {
  campaignSidebarFilter: string | undefined
  query?: string
  startDate?: string
  endDate?: string
}) => {
  /* fetches campaign counts by type and returns sidebar links */
  const dispatch = useDispatch()

  const counts = useSelector(getCampaignsHistoryCountsByType)

  React.useEffect(() => {
    const handle = axios.CancelToken.source()
    getCampaignCountsByType(query ?? '', handle.token, startDate, endDate).then(
      res => {
        if (isRight(res)) {
          dispatch(fetchCampaignCountByType(res.right))
        }
      }
    )
    return () => {
      handle.cancel()
    }
  }, [dispatch, query, startDate, endDate])

  return getSidebarLinks(campaignSidebarFilter, counts)
}

interface ICampaignContainerProps {
  readonly children: React.ReactElement
  readonly contentClassName?: string
  readonly passStyleToChild?: boolean
}
export function CampaignContainer({
  children,
  links,
  contentClassName,
  passStyleToChild,
}: ICampaignContainerProps & { links: MainstaySidebarLinkType[] }) {
  const location = useLocation()
  const { sidebar_filter: sidebarFilter } = getQueryFilters(location)
  const dispatch = useDispatch()

  React.useEffect(() => {
    if (sidebarFilter) {
      const selected = filterOptions.find(({ name }) => name === sidebarFilter)
      if (selected) {
        dispatch(setCampaignHistoryFilterBy(selected.filterBy))
      }
    } else if (sidebarFilter === undefined) {
      dispatch(setCampaignHistoryFilterBy(Filters.all))
    }
  }, [sidebarFilter, dispatch])

  return (
    <NavBarPage
      title="Campaigns"
      className="d-flex h-100"
      pageMainClassName="page-campaign-history text-mainstay-dark-blue-80">
      <MainstayPage
        header="Campaigns"
        links={links}
        passStyleToChild={passStyleToChild}
        pageContentClassName={contentClassName}>
        {children}
      </MainstayPage>
    </NavBarPage>
  )
}

function CampaignHistoryPageContent({
  campaigns,
  triggers,
  currentPage,
  sort,
  campaignSidebarFilter,
}: ICampaignHistoryOwnProps & ICampaignHistoryPageRouteProps) {
  const topScrollTarget = React.useRef<HTMLDivElement | null>(null)
  const dispatch = useDispatch()
  const history = useHistory()

  const [query, setQuery] = React.useState<string | undefined>()
  const debouncedQuery = useDebounce(query, DEBOUNCE_RATE)
  const [showCampaignFilterModal, setShowCampaignFilterModal] = React.useState(
    false
  )
  const [startDate, setStartDate] = React.useState<string | undefined>()
  const [endDate, setEndDate] = React.useState<string | undefined>()

  React.useEffect(() => {
    const { query } = getQueryFilters(window.location)
    if (query && typeof query === 'string') {
      setQuery(query)
    }
  }, [setQuery])

  React.useEffect(() => {
    const { start } = getQueryFilters(window.location)
    if (start && typeof start === 'string') {
      setStartDate(start)
    }
  }, [setStartDate])

  React.useEffect(() => {
    const { end } = getQueryFilters(window.location)
    if (end && typeof end === 'string') {
      setEndDate(end)
    }
  }, [setEndDate])

  React.useEffect(() => {
    if (campaignSidebarFilter === Filters.is_datatriggered) {
      fetchCampaignTriggersAsync(dispatch)({
        query: debouncedQuery ?? '',
        sort,
      })
      return
    }

    if (campaignSidebarFilter === Filters.is_hellopage) {
      // Hello Pages get fetched within its own component as there's no need to have them stored in redux.
      return
    }

    const handle = axios.CancelToken.source()
    fetchCampaignHistoryAsync(dispatch)({
      sidebarFilter: campaignSidebarFilter,
      query: debouncedQuery ?? '',
      page: currentPage,
      cancelToken: handle.token,
      sort,
      dateFilter: {
        start: startDate,
        end: endDate,
      },
    })

    return () => {
      handle.cancel()
    }
  }, [
    dispatch,
    debouncedQuery,
    currentPage,
    sort,
    campaignSidebarFilter,
    startDate,
    endDate,
  ])

  const handleScrollToTop = React.useCallback(() => {
    topScrollTarget.current?.scrollIntoView()
  }, [])

  React.useEffect(() => {
    history.replace(removeQueryFilter(window.location, 'page'))
    if (!query) {
      history.replace(removeQueryFilter(window.location, 'query'))
    } else {
      history.replace(appendQueryFilter(window.location, 'query', query))
    }
  }, [history, query])

  React.useEffect(() => {
    if (startDate) {
      history.replace(appendQueryFilter(window.location, 'start', startDate))
    } else {
      history.replace(removeQueryFilter(window.location, 'start'))
    }
  }, [startDate, history])

  React.useEffect(() => {
    if (endDate) {
      history.replace(appendQueryFilter(window.location, 'end', endDate))
    } else {
      history.replace(removeQueryFilter(window.location, 'end'))
    }
  }, [endDate, history])

  const links = useGetCampaignSidebarLinks({
    campaignSidebarFilter,
    query: debouncedQuery,
    startDate,
    endDate,
  })
  const title = links?.find(({ active }) => active === true)?.text

  const isNotIntro = [
    Filters.all,
    Filters.sent,
    Filters.sending,
    Filters.upcoming,
    Filters.recurring,
    Filters.is_datatriggered,
  ].includes(campaignSidebarFilter)

  const isHelloPage = campaignSidebarFilter === Filters.is_hellopage

  const searchInputPlaceholder = isHelloPage
    ? 'Title, URL, or Script Name'
    : 'Title or Description'

  return (
    <CampaignContainer links={links}>
      <>
        <PageHeader title={title ? `${title} Campaigns` : ''}>
          <PermissionGuard
            className="align-items-center"
            permission={PERMISSIONS.CAMPAIGN.CREATE}>
            <CreateButton
              title="Create Campaign"
              onClick={() => history.push('/schedule-campaign')}
              eventAction="click"
              eventObject="new campaign button"
            />
          </PermissionGuard>
        </PageHeader>
        <div className="d-flex mb-2rem">
          <SearchInput
            placeholder={searchInputPlaceholder}
            onChange={e => setQuery(e.target.value)}
            onClear={() => setQuery('')}
            value={query}
          />
          {isNotIntro && (
            <>
              <FilterButton
                eventLocation="filter campaigns by date"
                className="ml-3 align-items-center"
                onClick={() => setShowCampaignFilterModal(true)}
              />
              <CampaignFilterChip
                startDate={startDate}
                endDate={endDate}
                onClear={() => {
                  setStartDate(undefined)
                  setEndDate(undefined)
                }}
              />
            </>
          )}
        </div>
        {campaignSidebarFilter === Filters.is_webchat_intro && (
          <WebchatIntroCampaignsTable debouncedQuery={debouncedQuery} />
        )}
        {campaignSidebarFilter === Filters.is_other_intro && (
          <IntroCampaignsTable />
        )}
        {isHelloPage && (
          <HelloPagesCampaignTable debouncedQuery={debouncedQuery} />
        )}
        {isNotIntro && (
          <CampaignTable
            campaigns={campaigns}
            triggers={triggers}
            onPagerClick={handleScrollToTop}
            campaignSidebarFilter={campaignSidebarFilter}
          />
        )}
        <CampaignHistoryFilterModal
          show={showCampaignFilterModal}
          onClose={() => setShowCampaignFilterModal(false)}
          setStartDate={setStartDate}
          setEndDate={setEndDate}
          startDate={startDate}
          endDate={endDate}
        />
      </>
    </CampaignContainer>
  )
}

const mapStateToProps = (
  state: IState,
  ownProps: ICampaignHistoryPageRouteProps
): ICampaignHistoryOwnProps => {
  return {
    campaigns: getCampaignList(state),
    triggers: getCampaignTriggers(state),
    currentPage: getCurrentCampaignHistoryPage(ownProps),
    sort: getSortForCampaigns(state),
    campaignSidebarFilter: getCampaignSidebarFilter(state),
  }
}

export default withRouter(connect(mapStateToProps)(CampaignHistoryPageContent))

interface ICampaignFilterChipProps {
  onClear: () => void
  startDate?: string
  endDate?: string
}

const CHIP_DATE_FORMAT = 'MMMM d, yyyy'

function getFormattedDate(date: string): string {
  try {
    // Since we're parsing query string values here;
    // we need to anticipate users manually inputting incorrect formats within the url
    // by allowing the frontend to fail gracefully
    return format(
      parse(date, QUERY_PARAM_DATE_FORMAT, new Date()),
      CHIP_DATE_FORMAT
    )
  } catch (e) {
    return ''
  }
}

function CampaignFilterChip({
  onClear,
  startDate,
  endDate,
}: ICampaignFilterChipProps) {
  if (!startDate && !endDate) {
    return <></>
  }
  let label = ''
  const fmtStart = startDate && getFormattedDate(startDate)
  const fmtEnd = endDate && getFormattedDate(endDate)

  if (startDate && endDate) {
    label = `${fmtStart} - ${fmtEnd}`
  }
  if (startDate && !endDate) {
    label = `${fmtStart} and later`
  }
  if (!startDate && endDate) {
    label = `Up to ${fmtEnd}`
  }
  return <Chip label={label} type="clear" onClear={onClear} className="ml-2" />
}
