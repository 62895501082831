import 'page/conversations-v2/ConversationsDetail/ConversationsDetail.scss'
import { MessageOrigin } from 'page/conversations-v2/ConversationsDetail/index'
import { assertNever } from 'util/exhaustiveness'
import classnames from 'classnames'
import { INTERNAL } from 'const/routes'
import { Link } from 'util/routing'
import AdmithubOnly from 'components/AdmithubOnly/AdmithubOnly'
import { CaretIcon } from 'components/Icons/CaretIcon/CaretIcon'

export function GenerativeTransactionLink({
  messageId,
  transactionId,
}: {
  readonly messageId?: string
  readonly transactionId?: string
}) {
  if (messageId === undefined && transactionId === undefined) {
    return null
  }

  const queryParam =
    messageId !== undefined
      ? `used_for_response_sms_log_id=${messageId}`
      : `generative_text_service_transaction_id=${transactionId}`

  return (
    <AdmithubOnly allowStaffOnly fallback={null}>
      <Link to={`${INTERNAL.GENERATIVE_AI_TRANSACTIONS}?${queryParam}`}>
        <p className="bg-mainstay-warning-300 border chip mt-1 mb-1 pr-2 border-radius-lg caption">
          Gen AI Transaction
        </p>
      </Link>
    </AdmithubOnly>
  )
}

function OriginName({
  messageId,
  origin,
}: {
  readonly messageId: string
  readonly origin: MessageOrigin
}) {
  switch (origin.kind) {
    case 'understanding': {
      return (
        <>
          Understanding{' '}
          {origin.generativeTransactionId != null && (
            <GenerativeTransactionLink
              transactionId={origin.generativeTransactionId.toString()}
            />
          )}
        </>
      )
    }
    case 'fallback': {
      // If it's an IDK but there is a generative AI transaction ID then the gen ai found an answer when we couldn't find an understanding
      if (origin.generativeTransactionId != null) {
        return (
          <>
            Flash Response (Generative){' '}
            <GenerativeTransactionLink
              transactionId={origin.generativeTransactionId.toString()}
            />
          </>
        )
      }

      return <>Fallback Response</>
    }
    case 'dialog': {
      return <>Script</>
    }
    case 'ai_generated': {
      return (
        <>
          AI Generated Response
          <GenerativeTransactionLink messageId={messageId} />
        </>
      )
    }
    case 'ai_assisted_live_chat': {
      return <>AI-assisted Live Chat</>
    }
    default: {
      assertNever(origin)
    }
  }
}

export function ReplyHeader({
  messageId,
  origin,
  onClick,
  expanded,
}: {
  readonly messageId: string
  readonly origin: MessageOrigin
  readonly onClick: () => void
  readonly expanded: boolean
}) {
  return (
    <div
      className={classnames(
        'd-flex align-items-center border-radius-md border-bottom-left-radius-0 border-bottom-right-radius-0 px-3 py-1 bg-mainstay-dark-blue-10 pointer'
      )}
      onClick={onClick}>
      <CaretIcon className="caret-icon mr-1" expanded={expanded} />
      <div className="fs-0_8rem m-0">
        <OriginName messageId={messageId} origin={origin} />
      </div>
    </div>
  )
}
