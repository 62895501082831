import * as React from 'react'
import classnames from 'classnames'
import { IModalState, RoundedCircle } from 'page/calendar/Calendar'
import Tooltip from 'components/Tooltip/Tooltip'
import { Link } from 'util/routing'
import * as nope from 'yup'
import Time from 'components/TimeLocale/TimeLocale'
import { formatImportSegmentLabel } from 'util/importSegmentLabels'
import { AHIcon, AHIconType } from 'components/Icons/AHIcon/AHIcon'
import {
  CONTACTS,
  CAMPAIGN_SCHEDULER_ROUTES,
  CAMPAIGN_HISTORY,
} from 'const/routes'
import { useSelector, useFeatures, useDispatch } from 'util/hooks'
import { getFullCurrentInstitution } from 'store/triage/institution/selectors'
import PermissionGuard from 'util/permissions/PermissionGuard'
import { PERMISSIONS } from 'util/permissions/permissions'
import { RefreshIcon } from 'components/Icons/Refresh/Refresh'
import 'components/CalendarPopover/CalendarPopover.scss'
import { Button } from 'components/Button/Button'
import { Radio } from 'components/MaterialComponents/MaterialComponents'
import moment from 'moment-timezone'
import { DeleteRecurringCampaignTypeEnum } from 'store/scheduledCampaigns/reducer'
import { generatePath } from 'react-router'
import { InlineDateTimePicker } from 'components/InlineDateTimePicker/InlineDateTimePicker'
import { openEditRecurringCampaignEndDateModal } from 'store/sharedGlobalState/actions'

const ImportReportRecipient = ({
  displayString,
  importReportId,
  showLink,
  className,
}: {
  displayString: string
  importReportId: string | null
  showLink: boolean
  className?: string
}) =>
  importReportId && showLink ? (
    <PermissionGuard permission={PERMISSIONS.IMPORT.VIEW}>
      <Link
        className={className}
        to={`${CONTACTS.IMPORT_REPORT_LIST}${importReportId}`}>
        <div>{displayString}</div>
        <AHIcon name="open_in_new" className="ml-1" />
      </Link>
    </PermissionGuard>
  ) : (
    <span className={className}>{displayString}</span>
  )

export class RecipientGroup extends React.PureComponent<{
  importReportId: string | null
  recipientsFieldValue?: string
  recipientLabel: string | null
  filterName: string | null
  showLink: boolean
  className?: string
  contactFilter?: number | undefined
}> {
  render() {
    const {
      filterName,
      importReportId,
      recipientLabel,
      recipientsFieldValue,
      showLink,
      className,
      contactFilter,
    } = this.props

    if (recipientsFieldValue) {
      return <span className="text-ellipsis">{recipientsFieldValue}</span>
    }
    // Has recipient group
    else if (recipientLabel) {
      const labels = recipientLabel.split(/\s*,\s+/)
      return (
        <div>
          {labels.map(label => {
            const displayString = formatImportSegmentLabel(label)
            return (
              <div key={importReportId}>
                <ImportReportRecipient
                  importReportId={importReportId}
                  displayString={displayString}
                  showLink={showLink}
                  className={className}
                />
              </div>
            )
          })}
        </div>
      )
      // Has filter with a name
    } else if (filterName) {
      if (filterName.endsWith('(Deleted)')) {
        return <span> {filterName} </span>
      }
      return (
        <PermissionGuard permission={PERMISSIONS.AUDIENCE.VIEW}>
          <Link
            className={className}
            to={`${CONTACTS.AUDIENCES}${contactFilter}`}
            target="_blank">
            <div>
              {filterName} <AHIcon name="open_in_new" className="ml-1" />
            </div>
          </Link>
        </PermissionGuard>
      )
      // Has only filter query
    } else {
      return <span className={className}>None</span>
    }
  }
}

interface ICalendarPopoverRowProps {
  icon: AHIconType
  children: React.ReactNode
}

const CalendarPopoverRow = ({ icon, children }: ICalendarPopoverRowProps) => {
  return (
    <div className="mb-2 d-flex align-items-center">
      <AHIcon name={icon} className="mr-2 text-muted" />
      {children}
    </div>
  )
}

export const LockedEventIcon = ({ message }: { message: string }) => {
  return (
    <Tooltip content={message}>
      <div>
        <AHIcon name="lock" className="text-muted" />
      </div>
    </Tooltip>
  )
}

interface ICalendarPopoverContainerProps {
  modal: IModalState
  handleDelete: (
    deleteRecurringCampaignType?: DeleteRecurringCampaignTypeEnum,
    occurrenceDate?: Date
  ) => void
  regionFilteringEnabled: boolean
  handleConfirmReschedule: (date: string) => void
}

enum CampaignPopoverContent {
  deleteRecurringCampaign = 'deleteRecurringCampaign',
  default = 'default',
}

export function CalendarPopoverContainer({
  modal,
  handleDelete,
  regionFilteringEnabled,
  handleConfirmReschedule,
}: ICalendarPopoverContainerProps) {
  const [campaignPopoverContent, setCampaignPopoverContent] = React.useState<
    CampaignPopoverContent
  >(CampaignPopoverContent.default)

  const deletableCampaign = React.useMemo(() => {
    if (modal.recurring) {
      return moment.utc().isBefore(moment(modal.occurrenceDate).utc())
    }
    return !modal.started
  }, [modal.started, modal.occurrenceDate, modal.recurring])

  const { hasFeature, FeaturesType } = useFeatures()

  const dispatch = useDispatch()

  return (
    <div className="p-3 shadow border rounded bg-white calendar-popover-container">
      <div className="d-flex" />
      <div className="d-flex justify-content-end">
        {!deletableCampaign ? (
          <LockedEventIcon message="Past campaigns cannot be removed." />
        ) : !modal.hasPermissionToDelete &&
          hasFeature(FeaturesType.PERMS_REGIONS_ENABLED) ? (
          <LockedEventIcon message="You don't have permission to edit campaigns for this user group." />
        ) : (
          <>
            <PermissionGuard
              className="w-32px text-center"
              permission={PERMISSIONS.CAMPAIGN.DELETE}>
              <AHIcon
                name="delete"
                className={classnames(
                  'pointer large-icon text-mainstay-dark-blue-50 hover-text-new-ui-danger mr-2',
                  {
                    'not-allowed': modal.deleting,
                  }
                )}
                onClick={() => {
                  if (modal.recurring) {
                    return setCampaignPopoverContent(
                      CampaignPopoverContent.deleteRecurringCampaign
                    )
                  }
                  handleDelete()
                }}
              />
            </PermissionGuard>
            {!modal.started && (
              <PermissionGuard permission={PERMISSIONS.CAMPAIGN.EDIT}>
                <Link
                  className="text-decoration-none"
                  to={`${CAMPAIGN_SCHEDULER_ROUTES.EDIT_INDEX}${modal.id}${CAMPAIGN_SCHEDULER_ROUTES.TRIGGER_CONFIG}`}>
                  <AHIcon
                    name="edit"
                    className={classnames(
                      'pointer large-icon text-mainstay-dark-blue-50 hover-text-mainstay-dark-blue-80',
                      {
                        'not-allowed': modal.deleting,
                      }
                    )}
                  />
                </Link>
              </PermissionGuard>
            )}
            {modal.started && modal.recurring && (
              <PermissionGuard permission={PERMISSIONS.CAMPAIGN.EDIT}>
                <Link
                  className="text-decoration-none"
                  onClick={() =>
                    dispatch(openEditRecurringCampaignEndDateModal(true))
                  }
                  to={generatePath(CAMPAIGN_HISTORY.EDIT_DETAIL, {
                    id: modal.id,
                  })}>
                  <AHIcon
                    name="edit"
                    className={classnames(
                      'pointer large-icon text-mainstay-dark-blue-50 hover-text-mainstay-dark-blue-80',
                      {
                        'not-allowed': modal.deleting,
                      }
                    )}
                  />
                </Link>
              </PermissionGuard>
            )}
          </>
        )}
      </div>
      <CalendarPopoverContent
        modal={modal}
        regionFilteringEnabled={regionFilteringEnabled}
        handleConfirmReschedule={handleConfirmReschedule}
        campaignPopoverContent={campaignPopoverContent}
        setCampaignPopoverContent={setCampaignPopoverContent}
        handleDelete={handleDelete}
      />
    </div>
  )
}

function CampaignTime({
  modal,
  campaignDate,
  handleConfirmReschedule,
}: {
  modal: IModalState
  campaignDate: string | undefined
  handleConfirmReschedule: (date: string) => void
}) {
  const [errors, setErrors] = React.useState<string[]>([])
  const [editing, setEditing] = React.useState(false)
  const [dateTime, setDateTime] = React.useState<string | undefined>(
    campaignDate
  )

  const dateFormatString = moment.HTML5_FMT.DATE
  const timeFormatString = moment.HTML5_FMT.TIME_SECONDS

  const institution = useSelector(getFullCurrentInstitution)

  const institutionTimezone = institution?.timeZone || 'America/New_York'

  const campaignRescheduleValidationSchema = nope.object({
    date: nope
      .string()
      .required('You must choose a date.')
      .test(
        'scheduled-in-past',
        'Cannot schedule a campaign in the past!',
        (value: string): boolean => {
          if (!value) {
            return false
          }
          const setDateTime = moment.tz(value, institutionTimezone)
          return setDateTime.isAfter(moment.tz(institutionTimezone))
        }
      ),
  })

  const handleDayChange = (e: Date) => {
    const existingTime = moment
      .tz(dateTime, institutionTimezone)
      .format(timeFormatString) // append the already set time to new date so we aren't clearing out the time every time we choose a new date

    const formattedDate = moment
      .tz(e, institutionTimezone)
      .format(dateFormatString) // format this to date only part of UTC
    const newDateTime = moment.tz(
      `${formattedDate}T${existingTime}`,
      institutionTimezone
    )

    const newFormamttedDate = newDateTime.utc().format(moment.defaultFormatUtc) // format to utc

    setDateTime(newFormamttedDate)
  }

  const handleTimeChange = (e: moment.Moment | null) => {
    if (e == null) {
      return
    }

    const time = e.format(timeFormatString) // format this to time only part of UTC
    const formattedDate = moment
      .tz(dateTime, institutionTimezone)
      .format(dateFormatString) // format to date only part of string
    const timeZoneDate = moment.tz(
      `${formattedDate}T${time}`,
      institutionTimezone
    )
    const newDateTime = timeZoneDate.utc().format(moment.defaultFormatUtc) // format to utc

    setDateTime(newDateTime)
  }

  const rescheduleAvailable =
    !modal.recurring &&
    !modal.started &&
    !modal.deleting &&
    modal.hasPermissionToDelete

  const validateAndSubmit = async () => {
    try {
      await campaignRescheduleValidationSchema.validate({ date: dateTime })
      setErrors([])
      if (dateTime) {
        handleConfirmReschedule(dateTime)
      }
    } catch (err) {
      if (err instanceof nope.ValidationError) {
        setErrors(err.errors)
      }
    }
  }

  return (
    <div>
      {editing && (
        <>
          <div className="d-flex">
            <InlineDateTimePicker
              date={dateTime}
              institutionTimezone={institutionTimezone}
              handleDayChange={handleDayChange}
              variant="sm"
              hideLabels={true}
              handleTimeChange={handleTimeChange}
            />
            <Button
              color="primary"
              className="align-self-end ml-2"
              onClick={validateAndSubmit}>
              Save
            </Button>
          </div>
          <div className="ml-2 error">{errors}</div>
        </>
      )}

      {!editing && (
        <div className="d-flex align-items-start">
          <Time dateTime={modal.occurrenceDate || modal.on} />
          {rescheduleAvailable && (
            <PermissionGuard
              permission={PERMISSIONS.CAMPAIGN.EDIT}
              renderNothing>
              <AHIcon
                name="edit"
                onClick={() => setEditing(true)}
                className={classnames(
                  'pointer med-icon text-mainstay-dark-blue-50 hover-text-mainstay-dark-blue-80'
                )}
              />
            </PermissionGuard>
          )}
        </div>
      )}
    </div>
  )
}

interface ICalendarPopoverContentProps {
  modal: IModalState
  campaignPopoverContent: CampaignPopoverContent
  regionFilteringEnabled: boolean
  setCampaignPopoverContent: (
    campaignPopoverContent: CampaignPopoverContent
  ) => void
  handleDelete: (
    deleteRecurringCampaignType?: DeleteRecurringCampaignTypeEnum,
    occurrenceDate?: Date
  ) => void
  handleConfirmReschedule: (date: string) => void
}

function CalendarPopoverContent({
  modal,
  campaignPopoverContent,
  regionFilteringEnabled,
  setCampaignPopoverContent,
  handleConfirmReschedule,
  handleDelete,
}: ICalendarPopoverContentProps) {
  if (
    campaignPopoverContent === CampaignPopoverContent.deleteRecurringCampaign
  ) {
    return (
      <DeleteRecurringCampaignPopoverContent
        setCampaignPopoverContent={setCampaignPopoverContent}
        handleDelete={handleDelete}
        occurrenceDate={modal.occurrenceDate}
      />
    )
  }

  const campaignDate = modal.occurrenceDate?.toISOString()
  const recurringCampaignID =
    modal.completedRecurringCampaigns[
      campaignDate ? campaignDate.substring(0, 10) : ''
    ]
  return (
    <div className="d-flex flex-column">
      <div className="d-flex">
        {!moment.utc().isBefore(moment(modal.occurrenceDate).utc()) ||
        modal.started ? (
          <PermissionGuard permission={PERMISSIONS.CAMPAIGN.VIEW}>
            <Link
              to={
                recurringCampaignID
                  ? generatePath(CAMPAIGN_HISTORY.RECURRING_DETAIL, {
                      id: recurringCampaignID,
                    })
                  : generatePath(CAMPAIGN_HISTORY.DETAIL, { id: modal.id })
              }
              target="_blank"
              className="text-decoration-none">
              <div className="d-inline-flex">
                <h4 className="">{modal.title}</h4>
                <AHIcon name="open_in_new" className="ml-1 pt-1" />
              </div>
            </Link>
          </PermissionGuard>
        ) : (
          <h4>{modal.title}</h4>
        )}
        {modal.recurring && (
          <RefreshIcon className="ml-1 justify-content-right" />
        )}
      </div>
      <p className="text-mainstay-dark-blue-65 mb-2">{modal.description}</p>
      {regionFilteringEnabled && modal.regionDisplayName && (
        <div className="d-flex mb-2">
          <div className="mr-2 d-flex align-items-center justify-content-center">
            <RoundedCircle size={15} color={modal.color} />
          </div>
          <div className="text-muted">{modal.regionDisplayName}</div>
        </div>
      )}
      {/*
        The icons in the following divs CalendarPopoverRow have
        ~6px of white pixels within their svgs. In order to get the text above
        and the icons below perfectly vertically aligned, we need to do this unholy bologna below.
      */}
      <div
        className="mt-1"
        style={{
          marginLeft: '-7px',
        }}>
        <CalendarPopoverRow icon="send">
          <CampaignTime
            modal={modal}
            campaignDate={campaignDate}
            handleConfirmReschedule={handleConfirmReschedule}
          />
        </CalendarPopoverRow>
        <CalendarPopoverRow icon="people">
          <RecipientGroup
            importReportId={modal.importReportId}
            recipientLabel={modal.recipientLabel}
            filterName={modal.filterName}
            showLink={true}
            contactFilter={modal.contactFilter}
            className="d-inline-flex text-decoration-none"
          />
        </CalendarPopoverRow>
        <CalendarPopoverRow icon="comment">
          <PermissionGuard permission={PERMISSIONS.SCRIPT.VIEW}>
            <Link
              to={`/campaign-scripts/${modal.campaign}/view`}
              target="_blank"
              className="d-inline-flex text-decoration-none">
              <div>{modal.workflowHumanName}</div>
              <AHIcon name="open_in_new" className="ml-1" />
            </Link>
          </PermissionGuard>
        </CalendarPopoverRow>
      </div>
    </div>
  )
}

interface IDeleteCampaignPopoverContentProps {
  setCampaignPopoverContent: (
    campaignPopoverContent: CampaignPopoverContent
  ) => void
  occurrenceDate?: Date
  handleDelete: (
    deleteRecurringCampaignType?: DeleteRecurringCampaignTypeEnum,
    occurrenceDate?: Date
  ) => void
}

function DeleteRecurringCampaignPopoverContent({
  handleDelete,
  setCampaignPopoverContent,
  occurrenceDate,
}: IDeleteCampaignPopoverContentProps) {
  const [deleteType, setDeleteType] = React.useState<
    DeleteRecurringCampaignTypeEnum
  >()
  return (
    <div className="d-flex">
      <div className="pl-2">
        <h5 className="mb-0">Delete recurring event?</h5>
        <div className="my-3">
          <label className="d-flex align-items-center mb-0 pointer">
            <Radio
              color="primary"
              className="py-2 pl-0"
              value={DeleteRecurringCampaignTypeEnum.futureOccurrences}
              checked={
                deleteType === DeleteRecurringCampaignTypeEnum.futureOccurrences
              }
              onClick={() =>
                setDeleteType(DeleteRecurringCampaignTypeEnum.futureOccurrences)
              }
            />
            Delete this and all future occurrences
          </label>
          <label className="d-flex align-items-center mb-0 pointer mt-1">
            <Radio
              color="primary"
              className="py-2 pl-0"
              value={DeleteRecurringCampaignTypeEnum.occurrence}
              checked={
                deleteType === DeleteRecurringCampaignTypeEnum.occurrence
              }
              onClick={() =>
                setDeleteType(DeleteRecurringCampaignTypeEnum.occurrence)
              }
            />
            Delete only this occurrence
          </label>
        </div>
        <div className="d-flex">
          <Button
            disabled={!deleteType}
            className="mr-3"
            onClick={() =>
              deleteType && handleDelete(deleteType, occurrenceDate)
            }
            color="danger">
            Delete
          </Button>
          <Button
            onClick={() =>
              setCampaignPopoverContent(CampaignPopoverContent.default)
            }
            className="border-1px">
            Cancel
          </Button>
        </div>
      </div>
    </div>
  )
}
