import classnames from 'classnames'
import { CloseableImage } from 'components/CloseableImage/CloseableImage'
import { IVCard } from 'store/campaign-scripts/reducer'
import { VCard } from 'components/VCard/VCard'
interface IMediaNodeProps {
  readonly className?: string
  readonly url: string
  readonly onRemove?: () => void
  readonly vCard?: IVCard
  readonly editable: boolean
}

export default function MediaNode({
  className,
  url,
  onRemove,
  vCard,
  editable,
}: IMediaNodeProps) {
  if (vCard) {
    return <VCard {...vCard} showBorder={true} />
  }

  return (
    <div className={classnames('node', className)}>
      <CloseableImage
        image={url}
        visible
        onClose={editable ? onRemove : undefined}
      />
    </div>
  )
}
