import { useRef, useState } from 'react'
import { uploadImage } from 'api'
import { Button } from 'components/Button/Button'
import { toast } from 'mainstay-ui-kit/MainstayToast/MainstayToast'
import { IUploadResponse } from 'api/response'
import { CloseableImage } from 'components/CloseableImage/CloseableImage'

export interface IImageUpload {
  id: number
  collegeId: string
  bucket: string
  key: string
  completed: boolean
  contentType: string
  href: string
  name: string
  public: boolean
  size: number
}

interface IImageUploadField {
  uploadUrl?: string
  onChange: (upload: IImageUpload) => void
  onClose?: () => void
  buttonType?: 'link' | 'button'
}

const mapUploadResToFormState = (res: IUploadResponse) => {
  return {
    id: res.id,
    collegeId: res.college_id,
    bucket: res.bucket,
    key: res.key,
    completed: res.completed,
    contentType: res.content_type,
    href: res.href,
    name: res.name,
    public: res.public,
    size: res.size,
  }
}

export const ImageUpload = ({
  buttonType = 'link',
  uploadUrl,
  onChange,
  onClose,
}: IImageUploadField) => {
  const fileRef = useRef<HTMLInputElement | null>(null)

  const [uploadState, setUploadState] = useState<
    'uploading' | 'upload_failure' | 'upload_complete'
  >()

  const handleImageUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files === null || e.target.files.length === 0) {
      return
    }
    const file = e.target.files[0]
    setUploadState('uploading')
    uploadImage(file)
      .then(res => {
        onChange(mapUploadResToFormState(res))
        setUploadState('upload_complete')
      })
      .catch(_e => {
        setUploadState('upload_failure')
        toast('problem uploading image', { type: 'error' })
      })
  }

  const labeledButton = () => {
    if (buttonType === 'link') {
      return (
        <div>
          <Button
            color="link"
            className="p-0 fs-14"
            loading={uploadState === 'uploading'}
            loadingText="Uploading image..."
            onClick={() => {
              fileRef.current?.click()
            }}>
            Upload new image
          </Button>
          <p className="mb-0 fs-14 text-muted">
            Image must be a .png, .jpg, .gif, or .svg.
          </p>
        </div>
      )
    }

    if (buttonType === 'button') {
      return (
        <div className="d-flex justify-content-between w-100">
          <div>
            <p className="mb-0 fs-14 text-muted">
              Supported formats: .png, .jpg, .gif, or .svg.
            </p>
            <p className="mb-0 fs-14 text-muted">
              Recommended file size: &lt;1MB
            </p>
          </div>
          <Button
            color="mainstay-teal"
            loading={uploadState === 'uploading'}
            loadingText="Uploading image..."
            onClick={() => {
              fileRef.current?.click()
            }}>
            Upload new image
          </Button>
        </div>
      )
    }
  }

  return (
    <div>
      <div className="pb-2 d-flex align-items-start justify-content-between">
        {/* Hidden input we access with the "Upload new image" button  */}
        <input
          id="formImage-upload"
          type="file"
          ref={fileRef}
          accept="image/png, image/jpeg, image/gif, image/svg+xml"
          className="d-none"
          onChange={handleImageUpload}
        />
        {labeledButton()}
      </div>
      {uploadUrl &&
        (onClose ? (
          <CloseableImage visible={true} image={uploadUrl} onClose={onClose} />
        ) : (
          <img src={uploadUrl} className="image-size align-self-start" />
        ))}
    </div>
  )
}
