import * as React from 'react'
import { WebData, isFailure, isUnresolved } from 'store/webdata'
import 'page/CampaignDetailsPage.scss'
import { useDispatch, useSelector } from 'util/hooks'
import Loader from 'components/Loader/Loader'
import { ICampaignImportDetails } from 'store/campaign-details/reducer'
import { CSVIcon } from 'components/Icons/CSVIcon/CSVIcon'
import { getInstitutionDateFormat } from 'store/triage/institution/selectors'
import strftime from 'strftime'
import { NavLink } from 'util/routing'
import * as ROUTES from 'const/routes'
import classnames from 'classnames'
import 'components/CampaignImportSection/CampaignImportSection.scss'
import { Badge } from 'components/Badge/Badge'

import { fetchCampaignImportDetailsAsync } from 'store/campaign-details/thunks'

interface ICampaignImportRowProps {
  label: string
  value: string | number | JSX.Element | undefined
  className?: string
}

const CampaignImportRow = ({
  label,
  value,
  className,
}: ICampaignImportRowProps) => (
  <div className={classnames(className, 'row my-2 d-flex align-items-center')}>
    <div className="col text-right mainstay-header-h6 px-0 label">{label}</div>
    <div className="col text-left mainstay-body-caption value">{value}</div>
  </div>
)

interface ICampaignImportSectionProps {
  campaignId?: string
  campaignImportData: WebData<ICampaignImportDetails>
}

export const CampaignImportSection = ({
  campaignImportData,
  campaignId,
}: ICampaignImportSectionProps) => {
  const dispatch = useDispatch()
  const dateFormat = useSelector(getInstitutionDateFormat)

  const shouldFetchCampaignImportData =
    isUnresolved(campaignImportData) && campaignId

  React.useEffect(() => {
    if (shouldFetchCampaignImportData && campaignId) {
      fetchCampaignImportDetailsAsync(campaignId)(dispatch)
    }
  }, [dispatch, shouldFetchCampaignImportData, campaignId])

  return isUnresolved(campaignImportData) ? (
    <Loader />
  ) : isFailure(campaignImportData) ? (
    <div>Failed to fetch campaign import details</div>
  ) : (
    <>
      <div className="d-flex align-items-end mt-4">
        <div className="mr-2 mb-1">
          <CSVIcon />
        </div>
        <div className="mainstay-header-h4">
          {campaignImportData?.data.name}
        </div>
      </div>
      <div className="container mt-4 pl-0">
        <CampaignImportRow
          label="Upload Date"
          value={
            !!campaignImportData?.data.date
              ? strftime(dateFormat, new Date(campaignImportData?.data.date))
              : undefined
          }
        />
        <CampaignImportRow label="User" value={campaignImportData?.data.user} />
        <CampaignImportRow
          label="Contacts"
          value={campaignImportData?.data.countContacts}
        />
        <CampaignImportRow
          label="Import History"
          value={
            <NavLink
              eventLocation="campaigns"
              eventAction="click"
              eventObject="import history link"
              target="_blank"
              className="text-decoration-none expand-link mainstay-body-caption"
              to={`${ROUTES.CONTACTS.IMPORT_REPORT_LIST}${campaignImportData?.data.importReportId}`}>
              {campaignImportData?.data.name}
            </NavLink>
          }
        />
        <CampaignImportRow
          className="mt-4"
          label="Segment Label"
          value={
            <Badge pill className="mainstay-body-caption pill">
              {campaignImportData?.data.label}
            </Badge>
          }
        />
        <CampaignImportRow
          label="Contact Labels"
          value={
            <div>
              {campaignImportData?.data.contactLabels.map(label => (
                <Badge
                  key={label}
                  pill
                  className="mr-2 mainstay-body-caption pill">
                  {label}
                </Badge>
              ))}
            </div>
          }
        />
      </div>
      <hr />
    </>
  )
}
