import {
  createAudienceFromResponses,
  getAudienceNames,
  getReportPreferences,
  listContactLabelNames,
} from 'api'
import axios from 'axios'
import classnames from 'classnames'
import { Badge } from 'components/Badge/Badge'
import { Button } from 'components/Button/Button'
import 'components/CampaignResultsCard/CampaignResultsCard.scss'
import { ReportType } from 'store/campaign-details/reducer'
import { DateRangeCustomFieldsDownload } from 'page/IntroDialogDetailsPage'
import { OpenResponsesDrawer } from 'components/OpenResponsesDrawer/OpenResponsesDrawer'
import {
  GroupedProgressBars,
  ProgressBar,
  ProgressBarItem,
} from 'components/ProgressBar/ProgressBar'
import { TextInput } from 'components/TextInput/TextInput'
import {
  CustomCampaignReportDownloadModal,
  IPreferencesRequestPayload,
} from 'components/TrendsV2/CustomizedDownloadModal'
import { WireframeMessage } from 'components/WireframeMessage/WireframeMessage'
import { Field, FieldProps, Formik, FormikProps, yupToFormErrors } from 'formik'
import { useHistory } from 'react-router'
import { useParams } from 'react-router-dom'

import { isRight } from 'fp-ts/lib/Either'
import sortBy from 'lodash/sortBy'
import * as React from 'react'
import { generateCampaignReport as generateCampaignReportAction } from 'store/campaign-details/actions'
import {
  IChoiceResult,
  IWorkflowStepResult,
  PromptType,
} from 'store/campaign-scripts/reducer'
import { useDispatch, useFeatures, useSelector } from 'util/hooks'
import * as nope from 'yup'

import { CSSTransition } from 'components/CSSTransition/CSSTransition'

import { Checkbox } from 'components/Checkbox/Checkbox'
import { EventTrackerAttrAdder } from 'components/EventTracker/EventTracker'
import { CONTACTS } from 'const/routes'
import { isUndefined } from 'lodash'
import { toast } from 'mainstay-ui-kit/MainstayToast/MainstayToast'
import pluralize from 'pluralize'
import { IGenerateCampaignReportPayload } from 'store/campaign-details/thunks'
import { MainstayModal } from 'components/Modal/Modal'
import { UserGroupSelector } from 'components/UserGroupSelector/UserGroupSelector'
import { getUserGroups } from 'store/triage/profile/selectors'
import { PERMISSIONS } from 'util/permissions/permissions'
import PermissionGuard from 'util/permissions/PermissionGuard'

const ANIMATION_TIMEOUT = 500
interface IWorkflowStepResultsHeaderProps {
  prompt: string
  contacts: number
  size: 'sm' | 'lg'
  className?: string
}

const WorkflowStepResultsHeader = ({
  prompt,
  contacts,
  size,
  className,
}: IWorkflowStepResultsHeaderProps) => (
  <>
    {size === 'lg' ? (
      <div
        className={classnames(
          'd-flex justify-content-between align-items-center',
          className
        )}>
        <h5 className="mr-3">
          <WireframeMessage message={prompt} />
        </h5>
        <Badge color="primary">Sent to {contacts}</Badge>
      </div>
    ) : (
      <div className={classnames('d-flex flex-column', className)}>
        <span className="mt-2">
          <WireframeMessage message={prompt} />
        </span>
        <small className="text-muted mt-2 mb-2">
          {contacts} responses so far
        </small>
      </div>
    )}
  </>
)
interface IResponseSelections {
  responseSelections: IResponseSelection
  enableResponseSelection?: boolean
  onSelectResponse: (id: string) => void
}

interface IMultipleChoiceResultsProps {
  totalCount: number
  choices: IChoiceResult[]
  countChoiceResponsesAgainst: 'recipients' | 'respondents'
}

const ChoicePromptResults = (props: IMultipleChoiceResultsProps) => {
  const items = props.choices.map(c => ({
    value: c.value,
    label: c.label,
    maxValue: props.totalCount,
    className: 'bg-secondary',
  }))
  return <GroupedProgressBars items={items} />
}

const humanizedLabel = (label: string, type: PromptType) => {
  if (type === PromptType.boolean) {
    return label === 'true' ? 'Yes' : 'No'
  }
  return label
}

const ChoicePromptResultsV2 = ({
  workflowStepId,
  choices,
  totalCount,
  countChoiceResponsesAgainst,
  enableResponseSelection,
  responseSelections,
  onSelectResponse,
  promptType,
}: IMultipleChoiceResultsProps &
  IResponseSelections & {
    workflowStepId: string
    promptType: PromptType
  }) => {
  return (
    <>
      {choices.map(c => {
        const responseUniqueId = combineStateIdAndResponseChoice(
          workflowStepId,
          c.cleaned
        )
        return (
          <div key={c.label} className="survey-row pb-2">
            <div className="survey-label d-flex align-items-center text-ellipsis">
              <CSSTransition
                in={enableResponseSelection}
                timeout={ANIMATION_TIMEOUT}
                classNames="survey-checkbox"
                mountOnEnter
                unmountOnExit>
                <div className="mr-4 survey-checkbox-container">
                  <Checkbox
                    id={responseUniqueId}
                    type="check"
                    checked={!!responseSelections[responseUniqueId]?.checked}
                    onChange={() => onSelectResponse(responseUniqueId)}
                  />
                </div>
              </CSSTransition>
              <CSSTransition
                in={enableResponseSelection}
                timeout={ANIMATION_TIMEOUT}
                classNames="survey-question">
                <div className="text-ellipsis pr-4">
                  {humanizedLabel(c.label, promptType)}
                </div>
              </CSSTransition>
            </div>
            <CSSTransition
              in={enableResponseSelection}
              timeout={ANIMATION_TIMEOUT}
              classNames="survey-data">
              <div className="w-100">
                <ProgressBar className="border-radius-md">
                  <ProgressBarItem
                    className="bg-mainstay-spark-red border-radius-md"
                    maxValue={totalCount}
                    value={c.value}
                  />
                </ProgressBar>
                <div className="d-flex justify-content-between">
                  <span className="text-mainstay-dark-blue fs-0_8rem">
                    {totalCount
                      ? ((c.value / totalCount) * 100).toFixed()
                      : '0'}
                    %
                  </span>
                  <span className="text-mainstay-dark-blue fs-0_8rem">
                    {`${c.value} ${pluralize(
                      countChoiceResponsesAgainst,
                      c.value
                    )}`}
                  </span>
                </div>
              </div>
            </CSSTransition>
          </div>
        )
      })}
    </>
  )
}

const OpenPromptResults = () => (
  <div>
    This is an open response type question. Download the campaign report to see
    responses
  </div>
)

const OpenPromptResultsV2 = ({
  id,
  prompt,
  campaignId,
  responseCount,
  isRecurring,
  enableResponseSelection,
  responseSelections,
  onSelectResponse,
  isDataTriggered,
  isHelloPage,
  isWebBot,
  audience,
  isAggregate,
}: {
  id: string
  prompt: string
  campaignId: string
  isRecurring: boolean
  isAggregate: boolean
  audience?: string
  isDataTriggered?: boolean
  isHelloPage?: boolean
  isWebBot?: boolean
  responseCount: number
} & IResponseSelections) => {
  const [drawerOpen, setDrawerOpen] = React.useState(false)
  return (
    <>
      <div className="d-flex justify-content-between pb-2">
        <div className="d-flex">
          <CSSTransition
            in={enableResponseSelection}
            timeout={ANIMATION_TIMEOUT}
            classNames="survey-checkbox"
            mountOnEnter
            unmountOnExit>
            <div className="mr-4 survey-checkbox-container">
              <Checkbox
                id={id}
                type="check"
                checked={!!responseSelections[id]?.checked}
                onChange={() => onSelectResponse(id)}
              />
            </div>
          </CSSTransition>
          <CSSTransition
            in={enableResponseSelection}
            timeout={ANIMATION_TIMEOUT}
            classNames="survey-question">
            <div className="mainstay-body-paragraph text-mainstay-dark-blue-80">
              Open-text responses
            </div>
          </CSSTransition>
        </div>
        {responseCount > 0 && (
          <EventTrackerAttrAdder
            eventLocation={isDataTriggered ? 'triggers' : 'campaigns'}
            eventAction="click"
            eventObject="open text responses drawer open">
            <div
              onClick={() => setDrawerOpen(true)}
              className="mainstay-body-paragraph text-mainstay-dark-blue-80 text-mainstay-teal pointer">
              {`${responseCount} ${pluralize('response', responseCount)}`}
            </div>
          </EventTrackerAttrAdder>
        )}
      </div>

      {drawerOpen && (
        <OpenResponsesDrawer
          key={isAggregate ? 'aggregate' : 'instance'}
          show={drawerOpen}
          prompt={prompt}
          campaignId={campaignId}
          isDataTriggered={isDataTriggered}
          isHelloPage={isHelloPage}
          isWebBot={isWebBot}
          isRecurring={isRecurring}
          isAggregate={isAggregate}
          audience={audience}
          stateId={id}
          onClose={() => {
            setDrawerOpen(false)
          }}
        />
      )}
    </>
  )
}

interface IAudienceFromContactLabels {
  audienceName: string
  contactLabel: string
  region: string | null
}

interface IAudienceFromResponsesModal {
  show: boolean
  onSubmit: (
    audienceName: string,
    contactLabelText: string,
    region: string | null
  ) => void
  onCancel: () => void
}

const AudienceFromResponsesModal = ({
  show,
  onSubmit,
  onCancel,
}: IAudienceFromResponsesModal) => {
  const { hasFeature, FeaturesType } = useFeatures()
  const [existingAudienceNames, setExistingAudienceNames] = React.useState<
    string[]
  >([])
  const [existingContactLabels, setExistingContactLabels] = React.useState<
    string[]
  >([])

  const userGroups = useSelector(getUserGroups)

  React.useEffect(() => {
    getAudienceNames().then(res => {
      if (isRight(res)) {
        setExistingAudienceNames(res.right.audience_names)
      }
    })
    const handle = axios.CancelToken.source()

    listContactLabelNames({
      search: '',
      limit: 5000,
      cancelToken: handle.token,
    }).then(res => {
      if (isRight(res)) {
        setExistingContactLabels(res.right.labels)
      }
    })
    return () => handle.cancel()
  }, [])

  const validationSchema = nope.object().shape({
    audienceName: nope
      .string()
      .max(120, 'Audience name is too long')
      .trim()
      .required('Audience name cannot be blank')
      .test(
        'is-dupe',
        'This audience name is already in use. Try another.',
        (value: string) => {
          const lowerCaseNames = existingAudienceNames.map(name =>
            name.toLowerCase()
          )
          return !lowerCaseNames.includes(value.toLowerCase())
        }
      ),
    contactLabel: nope
      .string()
      .max(120, 'Contact label is too long')
      .trim()
      .required('Add a contact label.')
      .test(
        'is-dupe',
        'This label already exists. Try another.',
        (value: string) => {
          const lowerCaseNames = existingContactLabels.map(name =>
            name.toLowerCase()
          )
          return !lowerCaseNames.includes(value.toLowerCase())
        }
      ),
    region: nope.string().nullable(),
  })

  return (
    <Formik<IAudienceFromContactLabels>
      enableReinitialize
      initialValues={{
        audienceName: '',
        contactLabel: '',
        region: userGroups?.[0] || null,
      }}
      validate={(values: IAudienceFromContactLabels) =>
        validationSchema.validate(values, { abortEarly: false }).catch(err => {
          throw yupToFormErrors(err)
        })
      }
      onSubmit={({ audienceName, contactLabel, region }) =>
        onSubmit(audienceName, contactLabel, region)
      }
      render={(formikProps: FormikProps<IAudienceFromContactLabels>) => {
        const { errors } = formikProps
        const hasErrors = errors && Object.keys(errors).length > 0
        return (
          <MainstayModal
            wrapContentInForm
            show={show}
            className="max-width-500"
            onClose={onCancel}
            text="Save as Audience"
            submitTrackingEvent={{
              location: 'campaigns',
              action: 'click',
              object: 'save as audience modal save',
            }}
            cancelTrackingEvent={{
              location: 'campaigns',
              action: 'click',
              object: 'save as audience modal cancel',
            }}
            onCancel={onCancel}
            disableSubmit={hasErrors}
            submitText="Save"
            cancelText="Cancel">
            <Field
              name="contactLabel"
              render={({
                form,
                field,
              }: FieldProps<IAudienceFromContactLabels>) => (
                <div className="mb-4">
                  <div className="text-mainstay-dark-blue-80 my-2 pb-1">
                    Add a unique label to the contacts to be included in this
                    audience.
                  </div>
                  <TextInput
                    {...field}
                    id="contactLabel"
                    className="w-100 max-w-19rem py-4"
                    placeholder="Label"
                    onChange={event => {
                      field.onChange(event)
                    }}
                    error={
                      !!form.errors.contactLabel && form.touched.contactLabel
                    }
                  />
                  {form.errors.contactLabel && form.touched.contactLabel && (
                    <div className="text-danger small">
                      {form.errors.contactLabel}
                    </div>
                  )}
                </div>
              )}
            />
            <Field
              name="audienceName"
              render={({
                form,
                field,
              }: FieldProps<IAudienceFromContactLabels>) => (
                <div className="mb-4">
                  <div className="text-mainstay-dark-blue-80 my-2 pb-1">
                    Add a name for the audience.
                  </div>
                  <TextInput
                    {...field}
                    id="audienceName"
                    className="w-100 max-w-19rem py-4"
                    placeholder="Audience Name"
                    onChange={event => {
                      field.onChange(event)
                    }}
                    error={
                      !!form.errors.audienceName && form.touched.audienceName
                    }
                  />
                  {form.errors.audienceName && form.touched.audienceName && (
                    <div className="text-danger small">
                      {form.errors.audienceName}
                    </div>
                  )}
                </div>
              )}
            />
            {hasFeature(FeaturesType.PERMS_REGIONS_ENABLED) && (
              <Field
                id="region"
                name="region"
                render={({
                  form,
                  field,
                }: FieldProps<IAudienceFromContactLabels>) => (
                  <div className="mb-4">
                    <div className="text-mainstay-dark-blue-80 my-2 pb-1">
                      User Groups are enabled for this institution. This
                      audience is restricted to:{' '}
                    </div>
                    <UserGroupSelector
                      {...field}
                      className="w-100 max-w-19rem"
                      region={form.values.region}
                      onChange={event => {
                        form.setFieldValue('region', event.value)
                      }}
                    />
                    {form.errors.region && form.touched.region && (
                      <div className="text-danger small">
                        {form.errors.region}
                      </div>
                    )}
                  </div>
                )}
              />
            )}
          </MainstayModal>
        )
      }}
    />
  )
}

export const WorkflowStepResults = ({
  steps,
  size = 'lg',
}: {
  steps: IWorkflowStepResult[]
  size?: 'sm' | 'lg'
}) => {
  return (
    <>
      {sortBy(steps, x => x.position).map((s, i) => {
        return (
          <div
            key={s.id}
            className={classnames('mb-2', {
              'pb-5': size === 'lg',
              'border-bottom pb-3': i !== steps.length - 1,
            })}>
            <WorkflowStepResultsHeader
              size={size}
              prompt={s.personalizedPrompt || s.prompt}
              contacts={s.count}
              className={classnames({
                'mb-2': size === 'sm',
              })}
            />
            <div className={classnames({ small: size === 'sm' })}>
              {(s.promptType === PromptType.boolean ||
                s.promptType === PromptType.number) &&
              s.choices ? (
                <ChoicePromptResults
                  totalCount={s.count}
                  choices={s.choices}
                  countChoiceResponsesAgainst="recipients"
                />
              ) : (
                <OpenPromptResults />
              )}
            </div>
          </div>
        )
      })}
    </>
  )
}

const WorkflowStepResultsV2 = ({
  steps,
  enableResponseSelection,
  responseSelections,
  campaignId,
  isRecurring,
  isDataTriggered,
  isHelloPage,
  isWebBot,
  isAggregate,
  audience,
  onSelectResponse,
}: {
  steps: IWorkflowStepResult[]
  campaignId: string
  isRecurring: boolean
  isAggregate: boolean
  isDataTriggered?: boolean
  isHelloPage?: boolean
  isWebBot?: boolean
  audience?: string
} & IResponseSelections) => {
  // whether to compare multi choice selections against
  // 1. recipients: everyone who received the multi-choice prompt OR
  // 2. respondents: only those who responded to the multi-choice prompt
  const [
    countChoiceResponsesAgainst,
    setCountChoiceResponsesAgainst,
  ] = React.useState<'recipients' | 'respondents'>('respondents')

  return (
    <>
      {sortBy(steps, x => x.position).map((s, i) => {
        return (
          <div
            key={s.id}
            className={classnames('mb-3', {
              'pb-3': i !== steps.length - 1,
            })}>
            <WireframeMessage
              message={s.personalizedPrompt || s.prompt}
              messageClassName="wireframe-msg"
            />
            <span
              className={classnames('pointer text-new-ui-primary fs-0_8rem', {
                'fw-600': countChoiceResponsesAgainst === 'respondents',
              })}
              onClick={() => setCountChoiceResponsesAgainst('respondents')}>
              {`${s.totalResponseCount} ${pluralize(
                'response',
                s.totalResponseCount
              )}`}{' '}
            </span>
            <span className="text-mainstay-dark-blue-80 fs-0_8rem"> | </span>
            <span
              className={classnames('pointer text-new-ui-primary fs-0_8rem', {
                'fw-600': countChoiceResponsesAgainst === 'recipients',
              })}
              onClick={() => setCountChoiceResponsesAgainst('recipients')}>
              Sent to {s.count}
            </span>
            <div className="mt-0_5">
              {(s.promptType === PromptType.boolean ||
                s.promptType === PromptType.number) &&
              s.choices ? (
                <ChoicePromptResultsV2
                  workflowStepId={s.id}
                  totalCount={
                    countChoiceResponsesAgainst === 'recipients'
                      ? s.count
                      : s.totalResponseCount
                  }
                  countChoiceResponsesAgainst={countChoiceResponsesAgainst}
                  choices={s.choices}
                  enableResponseSelection={enableResponseSelection}
                  onSelectResponse={onSelectResponse}
                  responseSelections={responseSelections}
                  promptType={s.promptType}
                />
              ) : (
                <OpenPromptResultsV2
                  id={s.id}
                  prompt={s.personalizedPrompt || s.prompt}
                  campaignId={campaignId}
                  isRecurring={isRecurring}
                  isDataTriggered={isDataTriggered}
                  isHelloPage={isHelloPage}
                  isWebBot={isWebBot}
                  isAggregate={isAggregate}
                  responseCount={s.totalResponseCount}
                  audience={audience}
                  enableResponseSelection={enableResponseSelection}
                  onSelectResponse={onSelectResponse}
                  responseSelections={responseSelections}
                />
              )}
            </div>
          </div>
        )
      })}
    </>
  )
}

export interface IWorkflowResponseChoice {
  dialogStateId: string
  cleanedChoice: boolean | number | null
}

interface IResponseSelection {
  [idWithStep: string]: {
    checked: boolean
    promptType: PromptType.number | PromptType.boolean | PromptType.open
  }
}

const combineStateIdAndResponseChoice = (
  stateId: string,
  cleanedChoice: string | number
) => `${stateId}:${cleanedChoice}`

const separateStateIdAndResponseChoice = (combinedId: string) =>
  combinedId.split(':')

const mapToSelectedResponsesPayload = (data: IResponseSelection) => {
  return Object.keys(data)
    .filter(_id => data[_id].checked === true)
    .map(_id => {
      const [dialogStateId, choice] = separateStateIdAndResponseChoice(_id)
      let cleanedChoice: number | boolean | null | string = null
      if (data[_id].promptType === PromptType.boolean) {
        cleanedChoice = choice === 'true'
      }
      if (data[_id].promptType === PromptType.number) {
        cleanedChoice = parseInt(choice, 10)
      }
      if (data[_id].promptType === PromptType.open) {
        cleanedChoice = null
      }
      return { dialogStateId, cleanedChoice }
    })
}

export interface ICustomCampaignReportDownload {
  reportFileName?: string
  generateCampaignReport: (
    fields: Omit<IGenerateCampaignReportPayload, 'campaignId'>
  ) => Promise<void> | undefined
}

interface ICampaignResultsCardProps extends ICustomCampaignReportDownload {
  campaignId: string
  isRecurring: boolean
  workflowSteps: IWorkflowStepResult[]
  isRespondable?: boolean
  isAggregate: boolean
  isDataTriggered?: boolean
  isHelloPage?: boolean
  isWebBot?: boolean
  countCampaignsSent?: number
  audience?: string
}

const getInitialResponseSelections = (workflowSteps: IWorkflowStepResult[]) => {
  return workflowSteps.reduce((acc, step) => {
    const reducedChoices = (step.choices ?? []).reduce((acc2, choice) => {
      const _id = combineStateIdAndResponseChoice(step.id, choice.cleaned)
      return {
        ...acc2,
        [_id]: { checked: false, promptType: choice.promptType },
      }
    }, {})
    return { ...acc, ...reducedChoices }
  }, {})
}

export function CampaignResultsCard({
  reportFileName,
  campaignId,
  isRespondable,
  workflowSteps,
  isRecurring,
  isHelloPage,
  isWebBot,
  isDataTriggered,
  isAggregate,
  audience,
  countCampaignsSent,
  generateCampaignReport,
}: ICampaignResultsCardProps) {
  const history = useHistory()

  const [downloadButtonsVisible, setDownloadButtonsVisible] = React.useState(
    false
  )
  const [downloadType, setDownloadType] = React.useState<
    'custom' | 'default' | undefined
  >(undefined)

  const allowDateFiltering = isDataTriggered || isHelloPage || isWebBot
  const [showDownloadModal, setShowDownloadModal] = React.useState(false)

  const [showSaveAsAudienceModal, setShowSaveAsAudienceModal] = React.useState(
    false
  )
  const [responseSelections, setResponseSelections] = React.useState<
    IResponseSelection
  >(getInitialResponseSelections(workflowSteps))
  const [
    showNoResponseSelectedErrorType,
    setShowNoResponseSelectedErrorType,
  ] = React.useState<'audience' | 'report' | undefined>()
  const dispatch = useDispatch()

  const { dialogId } = useParams<{ dialogId: string }>()

  const reportTitle = () => {
    if (isDataTriggered) {
      return 'Trigger Report'
    }
    if (isHelloPage) {
      return 'Hello Page Report'
    }
    if (isWebBot) {
      return 'Webchat Intro Report'
    }
    if (isAggregate) {
      return 'Aggregate Report'
    }
    return 'Campaign Report'
  }

  const toggleDownloadButtonsVisible = () =>
    setDownloadButtonsVisible(prev => !prev)

  const handleCustomDownload = (isInteractive: boolean) => (
    preferences: IPreferencesRequestPayload
  ) => {
    if (generateCampaignReport) {
      let campaignReportType = ReportType.Campaign
      if (isDataTriggered) {
        campaignReportType = ReportType.Trigger
      }
      if (isHelloPage) {
        campaignReportType = ReportType.HelloPageCampaign
      }
      if (isWebBot) {
        campaignReportType = ReportType.WebchatIntroCampaign
      }

      setDownloadType('custom')
      generateCampaignReport({
        preferences,
        reportFileName: undefined,
        workflowResponses: isInteractive
          ? mapToSelectedResponsesPayload(responseSelections)
          : undefined,
        campaignReportType,
        isAggregate,
        onDownloadReportComplete: () => {
          setShowDownloadModal(false)
          setDownloadType(undefined)
        },
        audience,
      })
    }
  }

  const handleCreateAudienceFromSurveyResponses = (
    audienceName: string,
    contactLabelText: string,
    region: string | null
  ) => {
    createAudienceFromResponses({
      campaignId,
      audienceName,
      contactLabelText,
      workflowResponses: mapToSelectedResponsesPayload(responseSelections),
      isRecurring,
      isAggregate,
      isHelloPage,
      isWebBot,
      dialogId,
      isDataTriggered,
      audience,
      region,
    }).then(res => {
      if (isRight(res)) {
        toast(
          `The label ${contactLabelText} has been created and used to define the audience ${audienceName}`,
          {
            type: 'success',
            link: {
              onClick: () =>
                history.push(`${CONTACTS.AUDIENCES}${res.right.audience_id}/`),
              text: 'View Audience',
            },
            options: { autoClose: 4000 },
            trackingEvent: {
              location: 'campaign',
              action: 'click',
              object: 'save as audience toast link',
            },
          },
          'Audience Saved'
        )
      } else {
        toast('Could not create the audience', { type: 'error' })
      }
    })
    setShowSaveAsAudienceModal(false)
  }

  const onCloseModal = React.useCallback(() => {
    setShowDownloadModal(false)
  }, [])

  const handleFormError = () => {
    if (campaignId) {
      dispatch(generateCampaignReportAction.failure({ campaignId }))
    }
  }
  const btnRef = React.useRef<HTMLButtonElement>(null)

  const getPreferenceData = React.useCallback(() => {
    let insight = ReportType.Campaign
    if (isDataTriggered) {
      insight = ReportType.Trigger
    }
    if (isHelloPage) {
      insight = ReportType.HelloPageCampaign
    }
    if (isWebBot) {
      insight = ReportType.WebchatIntroCampaign
    }

    return getReportPreferences({
      insight,
      isRecurring,
      isDataTriggered,
      isHelloPage,
      isWebBot,
      dialogId,
      id: campaignId,
    })
  }, [
    campaignId,
    isRecurring,
    isDataTriggered,
    isHelloPage,
    isWebBot,
    dialogId,
  ])

  const customReportDownloading = downloadType === 'custom'

  const noResponseSelected = !Object.keys(responseSelections).some(
    _id => responseSelections[_id].checked === true
  )

  const noResponseSelectedError =
    showNoResponseSelectedErrorType === 'audience' ? (
      <div>
        <span>
          Make a selection from the Survey Results section to save an audience.
        </span>
      </div>
    ) : (
      <div className="d-flex align-items-center">
        <span>
          Make a selection from the Survey Results section, or download the
          complete report.
        </span>
      </div>
    )

  /* If campaign is a Nudge campaign */
  if (!isRespondable && workflowSteps.length === 0) {
    return (
      <div className="w-100 d-flex justify-content-end">
        {!allowDateFiltering && (
          <CustomCampaignReportDownloadModal
            eventLocation="campaigns"
            reportTitle={reportTitle()}
            initialFileName={`${reportFileName ?? 'report_campaign'}_responses`}
            show={showDownloadModal}
            getPreferenceData={getPreferenceData}
            onClose={onCloseModal}
            submissionInProgress={customReportDownloading}
            onSubmit={handleCustomDownload(false)}
            onFormError={handleFormError}
            isAggregate={isAggregate}
            countCampaignsSent={countCampaignsSent}
          />
        )}
        {allowDateFiltering && showDownloadModal && (
          <DateRangeCustomFieldsDownload
            isAggregate={isAggregate}
            isDownloading={customReportDownloading}
            hideCampaignHistory={true}
            reportTitle={reportTitle()}
            initialFileName={`${reportFileName ?? 'report_trigger'}_responses`}
            getPreferenceData={getPreferenceData}
            onSubmit={handleCustomDownload(false)}
            onFormError={handleFormError}
            countCampaignsSent={countCampaignsSent}
            onClose={onCloseModal}
          />
        )}
        <PermissionGuard permission={PERMISSIONS.REPORT.VIEW}>
          <Button
            eventLocation="campaigns"
            eventAction="click"
            eventObject="nudge download report"
            className="bg-white text-secondary-teal border-1px border-secondary-teal"
            onClick={() => setShowDownloadModal(true)}>
            Download Report
          </Button>
        </PermissionGuard>
      </div>
    )
  }

  /* If campaign is an Interactive campaign */
  return (
    <>
      {!allowDateFiltering && (
        <CustomCampaignReportDownloadModal
          eventLocation="campaigns"
          reportTitle={reportTitle()}
          initialFileName={`${reportFileName ?? 'report_campaign'}_responses`}
          show={showDownloadModal}
          getPreferenceData={getPreferenceData}
          onClose={onCloseModal}
          submissionInProgress={customReportDownloading}
          onSubmit={handleCustomDownload(true)}
          onFormError={handleFormError}
          isAggregate={isAggregate}
          countCampaignsSent={countCampaignsSent}
        />
      )}
      {allowDateFiltering && showDownloadModal && (
        <DateRangeCustomFieldsDownload
          isAggregate={isAggregate}
          isDownloading={customReportDownloading}
          hideCampaignHistory={true}
          reportTitle={reportTitle()}
          initialFileName={`${reportFileName ?? 'report_trigger'}_responses`}
          getPreferenceData={getPreferenceData}
          onSubmit={handleCustomDownload(true)}
          countCampaignsSent={countCampaignsSent}
          onFormError={handleFormError}
          onClose={onCloseModal}
        />
      )}
      <AudienceFromResponsesModal
        show={showSaveAsAudienceModal}
        onCancel={() => setShowSaveAsAudienceModal(false)}
        onSubmit={handleCreateAudienceFromSurveyResponses}
      />
      <div className="pb-4">
        <div className="mb-4 d-flex justify-content-between">
          <div className="text-mainstay-dark-blue m-0 mainstay-header-h4-overview">
            Survey Results
          </div>
          <Button
            eventLocation="campaigns"
            eventAction="click"
            eventObject={
              'export results view ' +
              (downloadButtonsVisible ? 'cancel' : 'open')
            }
            height="outline-small"
            ref={btnRef}
            className="bg-white text-secondary-teal border-1px border-secondary-teal export-survey-btn"
            onClick={() => {
              btnRef.current?.blur()
              toggleDownloadButtonsVisible()
            }}>
            <h6 className="m-0 fw-600">
              {downloadButtonsVisible ? 'Cancel' : 'Export Results'}
            </h6>
          </Button>
        </div>

        <CSSTransition
          in={downloadButtonsVisible}
          timeout={ANIMATION_TIMEOUT}
          classNames="survey-report-download"
          mountOnEnter>
          <div className="mb-4">
            <span>
              Select responses to add to a report or save as an audience.
            </span>
          </div>
        </CSSTransition>
        <CSSTransition
          in={downloadButtonsVisible}
          timeout={ANIMATION_TIMEOUT}
          classNames="workflow-responses">
          <div>
            <WorkflowStepResultsV2
              campaignId={campaignId}
              audience={audience}
              steps={workflowSteps}
              isRecurring={isRecurring}
              isAggregate={isAggregate}
              isHelloPage={isHelloPage}
              isWebBot={isWebBot}
              isDataTriggered={isDataTriggered}
              enableResponseSelection={downloadButtonsVisible}
              onSelectResponse={(responseUniqueId: string) => {
                setResponseSelections(prev => ({
                  ...prev,
                  [responseUniqueId]: {
                    ...prev[responseUniqueId],
                    checked: !prev[responseUniqueId]?.checked,
                  },
                }))
              }}
              responseSelections={responseSelections}
            />
            {downloadButtonsVisible && (
              <div>
                {showNoResponseSelectedErrorType && noResponseSelected && (
                  <div className="text-danger d-flex justify-content-end">
                    {noResponseSelectedError}
                  </div>
                )}
                <div className="pb-5 py-3 d-flex justify-content-end">
                  <PermissionGuard permission={PERMISSIONS.AUDIENCE.CREATE}>
                    <Button
                      eventLocation="campaigns"
                      eventAction="click"
                      eventObject="save as audience modal open"
                      color="secondary-teal"
                      className="mr-2"
                      disabled={!isUndefined(downloadType)}
                      onClick={() => {
                        if (!noResponseSelected) {
                          setShowSaveAsAudienceModal(true)
                        } else {
                          setShowNoResponseSelectedErrorType('audience')
                        }
                      }}>
                      Save as audience
                    </Button>
                  </PermissionGuard>
                  <PermissionGuard permission={PERMISSIONS.REPORT.VIEW}>
                    <Button
                      eventLocation="campaigns"
                      eventAction="click"
                      eventObject="download report modal open"
                      className="bg-white text-secondary-teal border-1px border-secondary-teal"
                      disabled={!isUndefined(downloadType)}
                      onClick={() => {
                        if (!noResponseSelected) {
                          setShowDownloadModal(true)
                        } else {
                          setShowNoResponseSelectedErrorType('report')
                        }
                      }}>
                      Download Report
                    </Button>
                  </PermissionGuard>
                </div>
              </div>
            )}
          </div>
        </CSSTransition>
      </div>
    </>
  )
}
