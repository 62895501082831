import { toast } from 'mainstay-ui-kit/MainstayToast/MainstayToast'
import * as ACTION_TYPES from 'store/knowledgeSeeder/actionTypes'
// eslint-disable-next-line no-restricted-imports
import { action, createStandardAction } from 'typesafe-actions'
import * as Api from 'api'
import { Dispatch } from 'store/store'
import { AxiosError } from 'axios'

import { IFetchKnowledgeSeedsResponseData } from 'api/response'
import { toastOnHttpError500or400 } from 'api/http'
import { ResponsesFilters } from 'store/knowledgeSeeder/responsesFilterTypes'

// TODO(sbdchd): use createAsyncAction or createStandardAction

export const fetchKnowledgeSeedsStart = () =>
  action(ACTION_TYPES.FETCH_KNOWLEDGE_SEEDS_START)

interface IFetchKnowledgeSeedsSuccess {
  data: IFetchKnowledgeSeedsResponseData
}
export const fetchKnowledgeSeedsSuccess = (
  payload: IFetchKnowledgeSeedsSuccess
) => action(ACTION_TYPES.FETCH_KNOWLEDGE_SEEDS_SUCCESS, payload)

interface IKnowledgeSeed {
  sampleQuestion: null | string
  sampleAnswer: null | string
  id: string
  topic: string
  category: string
  subCategory: string
  hasApprovedAnswer: boolean
  fuzzyQuestionCount?: number | null
  responsesFilters: ResponsesFilters
  sampleAnswerIsGenerative: boolean
}
export const addKnowledgeSeed = createStandardAction(
  ACTION_TYPES.ADD_KNOWLEDGE_SEED
)<IKnowledgeSeed>()

export const fetchKnowledgeSeedsFailure = (error: Api.IApiError) =>
  action(ACTION_TYPES.FETCH_KNOWLEDGE_SEEDS_ERROR, error)

export const fetchKnowledgeSeedsAsync = (dispatch: Dispatch) => {
  dispatch(fetchKnowledgeSeedsStart())
  return Api.fetchKnowledgeSeeds()
    .then(response => {
      dispatch(fetchKnowledgeSeedsSuccess({ data: response.data }))
    })
    .catch((err: AxiosError) => {
      dispatch(fetchKnowledgeSeedsFailure(err))
      toast(
        'There was an error retrieving knowledge seeds. Please try again.',
        { type: 'error' }
      )
    })
}

export const removeKnowledgeSeedStart = (id: string) =>
  action(ACTION_TYPES.REMOVE_KNOWLEDGE_SEED_START, { id })

export const removeKnowledgeSeedSuccess = (id: string) =>
  action(ACTION_TYPES.REMOVE_KNOWLEDGE_SEED_SUCCESS, {
    id,
  })

export const removeKnowledgeSeedFailure = (id: string, error: Api.IApiError) =>
  action(ACTION_TYPES.REMOVE_KNOWLEDGE_SEED_ERROR, { id, error })

export const removeKnowledgeSeedAsync = (id: string) => {
  return (dispatch: Dispatch): Promise<{ ok: true } | { ok: false }> => {
    dispatch(removeKnowledgeSeedStart(id))
    return Api.removeKnowledgeSeed(id)
      .then(() => {
        dispatch(removeKnowledgeSeedSuccess(id))
        toast(
          'Understanding successfully archived. It will no longer be visible in your bot’s knowledge base.',
          {
            type: 'success',
          }
        )
        return { ok: true }
      })
      .catch((err: AxiosError) => {
        dispatch(removeKnowledgeSeedFailure(id, err))
        toastOnHttpError500or400(err)
        return { ok: false }
      })
  }
}
export const updateKnowledgeSeedApproval = createStandardAction(
  ACTION_TYPES.UPDATE_KNOWLEDGE_SEED_APPROVAL
)<{
  readonly understandingId: string
  readonly hasApprovedAnswer: boolean
  readonly sampleAnswer: string | null
  readonly sampleDialogId: string | null
  readonly sampleDialogName: string | null
  readonly sampleAnswerIsGenerative: boolean
  readonly responsesFilters: ResponsesFilters
}>()

export type IKnowledgeSeederActions =
  | ReturnType<typeof fetchKnowledgeSeedsStart>
  | ReturnType<typeof fetchKnowledgeSeedsSuccess>
  | ReturnType<typeof fetchKnowledgeSeedsFailure>
  | ReturnType<typeof removeKnowledgeSeedStart>
  | ReturnType<typeof removeKnowledgeSeedSuccess>
  | ReturnType<typeof removeKnowledgeSeedFailure>
  | ReturnType<typeof updateKnowledgeSeedApproval>
  | ReturnType<typeof addKnowledgeSeed>
