import React from 'react'
import 'components/KnowledgeSeedEditPanel/KnowledgeSeedEditPanel.scss'
import {
  ISegmentOption,
  ReadOnlyContactSegmentSelect,
} from 'components/ContactSegmentSelect/ContactSegmentSelect'
import { useParseAnswer } from 'components/DraftEditor/draftUtils'
import { ResponseCard } from 'components/ResponseCard/ResponseCard'
import { BasicResponseHeader } from 'components/ResponseCard/ResponseCardHeader'
import {
  BasicResponseCardContentReadMode,
  BasicResponseCardContentWriteMode,
  ScriptResponseContent,
} from 'components/ResponseCard/ResponseCardContent'
import {
  IAnswerSaveButtonProps,
  ResponseCardFooter,
  ScriptResultsSection,
} from 'components/ResponseCard/ResponseCardFooter'
import { Button } from 'components/Button/Button'
import classNames from 'classnames'
import { AHIcon } from 'components/Icons/AHIcon/AHIcon'
import { IAutoSuggestOption } from 'components/AutoSuggest/AutoSuggest'
import { IDialogForUnknownContact } from 'api/response'
import { ICampaignScriptStep } from 'store/campaign-scripts/reducer'
import { DraggableProvidedDragHandleProps } from 'react-beautiful-dnd'
import PermissionGuard from 'util/permissions/PermissionGuard'
import { PERMISSIONS } from 'util/permissions/permissions'
import { AlertBanner, AlertType } from 'components/Alert/Alert'
import { isUndefined } from 'lodash'
import { noOp } from 'util/noOp'
import { IDeleteButtonProps } from 'components/FloatingTrashCan/FloatingTrashCan'
import { GenAIDraftEditorProps } from 'components/DraftEditor/DraftEditor'
import Select from 'components/Select/SelectV2'
import { ReferenceContentCard } from 'components/ContentCard/ContentCard'
import { FireFlyIcon } from 'components/FireflyIcon/FireflyIcon'
import { useFeatures } from 'util/hooks'
import { FeaturesType } from 'components/Feature/Feature'

export const defaultHelpMessage = (
  <span>
    Default responses can be sent to any contact, and will be sent if no
    personalized responses are available. Responses from this section are chosen
    at random.
  </span>
)
export const personalizedHelpMessage = (
  <span>
    Share a response with a particular contact group, or use information from a
    contact's profile. Contacts will receive the first personalized response for
    which they meet all criteria.
  </span>
)

export interface IUpdateOrCreateResponseRequest {
  id: number | undefined
  answer?: string
  dialogId?: string
  generative: boolean
  approved: boolean
  contactFilter?: number
  order?: number
  genAITransactionId?: number
}

interface IKBResponseWriteProps {
  readonly className?: string
  readonly answerId?: number
  readonly initialAnswer?: string
  readonly dialogId: string | null
  readonly dialogName: string | null
  readonly generative: boolean
  readonly approved: boolean
  readonly selectedFilter?: number
  readonly onCancel: () => void
  readonly onSave: (
    request: IUpdateOrCreateResponseRequest,
    moveToTop?: boolean
  ) => void
  readonly onDelete: (() => void) | undefined
  readonly lastModifiedByName?: string
  readonly lastModifiedAt?: string
  readonly lastEmbeddedAt?: string | null
  readonly hideApproval?: boolean
  readonly allowPersonalization?: boolean
  readonly addingPersonalizedResponse?: boolean
  readonly workflowSteps: ICampaignScriptStep[]
  readonly dragHandleProps?: DraggableProvidedDragHandleProps
  readonly deleteButtonProps?: IDeleteButtonProps
  readonly shouldBePersonalized?: boolean
  readonly genAIProps?: GenAIDraftEditorProps
  readonly sensitiveTopic: boolean
}

const AnswerType = {
  Answer: 'answer',
  Interactive: 'interactive',
  Generative: 'generative',
} as const

type AnswerTypeType = typeof AnswerType[keyof typeof AnswerType]

export function KBResponseWrite({
  className,
  answerId,
  initialAnswer,
  approved,
  selectedFilter,
  onCancel,
  onSave,
  lastModifiedByName,
  lastModifiedAt,
  lastEmbeddedAt,
  hideApproval = false,
  onDelete,
  dialogId,
  dialogName,
  generative,
  allowPersonalization = true,
  addingPersonalizedResponse = false,
  workflowSteps,
  dragHandleProps,
  deleteButtonProps,
  shouldBePersonalized,
  genAIProps,
  sensitiveTopic,
}: IKBResponseWriteProps) {
  const [localAnswer] = React.useState<string | undefined>(initialAnswer)
  const [steps, setSteps] = React.useState(workflowSteps)
  const [editingAnswer, setEditingAnswer] = React.useState<string | undefined>(
    initialAnswer
  )
  const [selectedSegment, setSelectedSegment] = React.useState<
    number | undefined
  >(selectedFilter)
  const [editingApproval, setEditingApproval] = React.useState<boolean>(
    approved
  )
  const [showAudienceSelector, setShowAudienceSelector] = React.useState(
    selectedSegment !== undefined
  )

  const { hasFeature } = useFeatures()

  const answerTypeOptions: { label: string; value: AnswerTypeType }[] = [
    {
      label: 'Provide a static response',
      value: AnswerType.Answer,
    },
    {
      label: 'Provide an interactive response',
      value: AnswerType.Interactive,
    },
    ...(hasFeature(FeaturesType.ANSWERS_GENERATIVE_MASTER) &&
    hasFeature(FeaturesType.ANSWERS_GENERATIVE_PARTNER_SETTING)
      ? [
          {
            label: 'Generate a learner-specific response',
            value: AnswerType.Generative,
          },
        ]
      : []),
  ]

  const {
    selectedContactAttributes,
    hasValidAttributes: hasValidMustacheTemplate,
  } = useParseAnswer(editingAnswer)

  const [answerType, setAnswerType] = React.useState<AnswerTypeType>(
    generative
      ? AnswerType.Generative
      : !!dialogId
      ? AnswerType.Interactive
      : AnswerType.Answer
  )
  const [script, setScript] = React.useState<
    IAutoSuggestOption<IDialogForUnknownContact>
  >({ value: dialogId || '', label: dialogName || '' })

  const currentlyHasContactAttributes = selectedContactAttributes.length > 0
  const isPersonalized = !!selectedSegment || currentlyHasContactAttributes

  const onSelectSegmentChange = (option?: ISegmentOption) => {
    const newfilterId = option?.value
    setSelectedSegment(newfilterId)
  }

  function handleSave() {
    let payload: IUpdateOrCreateResponseRequest = {
      id: undefined,
      answer: answerType === AnswerType.Answer ? editingAnswer : undefined,
      dialogId:
        answerType === AnswerType.Interactive ? script.value : undefined,
      generative: answerType === AnswerType.Generative,
      approved: editingApproval,
      contactFilter: selectedSegment,
    }
    if (answerId !== undefined) {
      payload = { ...payload, id: answerId }
    }
    // If the user was adding a unpersonalized answer but then personalized it,
    // we should put it at the top of the personalized list.
    const moveToTop = !addingPersonalizedResponse && isPersonalized
    onSave(payload, !!moveToTop)
  }

  const content = () => {
    switch (answerType) {
      case AnswerType.Generative:
        return (
          <div className="p-3">
            <span className="caption">Response</span>
            <ReferenceContentCard
              type="Generative"
              className="p-0"
              icon={
                <div className="firefly p-0 pl-2">
                  <FireFlyIcon />
                </div>
              }
              fillIcon={false}>
              <div className="font-italic">AI Generated Response</div>
            </ReferenceContentCard>
          </div>
        )
      case AnswerType.Interactive:
        return (
          <ScriptResponseContent
            label="Response"
            onChange={val => {
              if (val.target.data && !Array.isArray(val.target.data)) {
                setScript(val.target.data)
                setSteps([])
              }
            }}
            value={script}
            name="inputName"
            editing={true}>
            {steps && steps.length > 0 ? (
              <ScriptResultsSection
                title="Script Survey Results"
                info="If this script was also used in a campaign those results will appear here."
                steps={steps.map(x => ({
                  choices: x.choiceResponseCounts ?? null,
                  prompt: x.prompt,
                  personalizedPrompt: x.personalizedPrompt,
                  promptType: x.promptType,
                  id: x.id,
                  count: x.totalResponseCount || 0,
                  totalResponseCount: x.totalResponseCount || 0,
                }))}
              />
            ) : null}
          </ScriptResponseContent>
        )
      case AnswerType.Answer:
      default:
        return (
          <>
            {
              <BasicResponseCardContentWriteMode
                response={localAnswer || ''}
                hasContactAttributes={currentlyHasContactAttributes}
                onChangeAnswer={setEditingAnswer}
                disableAttributes={!allowPersonalization}
                genAIProps={genAIProps}
              />
            }{' '}
          </>
        )
    }
  }

  return (
    <ResponseCard
      mode="write"
      className={classNames(className, 'mt-3')}
      loading={false}
      // in the case of creation, we only handle approvals locally
      // in the case of update, we handle approvals w/ the passed in onChangeApprovalProp
      // either way, the editingApproval state variable will be updated and sent to the onSave()
      approved={editingApproval}
      onApprove={() => setEditingApproval(true)}
      onUnapprove={() => setEditingApproval(false)}
      lastModifiedAt={lastModifiedAt}
      lastModifiedByName={lastModifiedByName}
      lastEmbeddedAt={lastEmbeddedAt}
      hideApproval={hideApproval}
      header={
        <BasicResponseHeader
          onDelete={onDelete}
          editing={true}
          dragHandleProps={dragHandleProps}
          deleteButtonProps={deleteButtonProps}
          audience={
            allowPersonalization
              ? {
                  selector: (
                    <>
                      <div className="px-3 pt-0 mb-3">
                        {!showAudienceSelector ? (
                          <span
                            color="link"
                            onClick={() => setShowAudienceSelector(true)}
                            className="pl-0 text-left text-secondary-teal pointer text-underline-on-hover">
                            Set an Audience
                          </span>
                        ) : (
                          <div className="mt-1">
                            <ReadOnlyContactSegmentSelect
                              selectedFilterId={selectedSegment}
                              onChange={onSelectSegmentChange}
                              onNewSegment={noOp}
                              placeholder="Audience Name"
                              showCreateNewButton={false}
                              className="z-index-10"
                              isClearable
                            />
                          </div>
                        )}
                      </div>
                      <hr className="m-0 mb-2" />
                    </>
                  ),
                  targetAudienceInfo: (
                    <div className="px-3 mt-3 position-relative pb-1 caption">
                      Who can receive this response?
                    </div>
                  ),
                }
              : null
          }
        />
      }
      content={
        <>
          {!sensitiveTopic && (
            <>
              <span className="caption ml-3">Response Type</span>
              <Select<{ label: string; value: AnswerTypeType }>
                className="p-0 border-0 w-75 mb-2 ml-3"
                selectClassName="z-index-100"
                options={answerTypeOptions}
                value={answerTypeOptions.find(
                  option => option.value === answerType
                )}
                onChange={option => {
                  if (option && !Array.isArray(option)) {
                    setAnswerType(option.value)
                  }
                }}
              />
            </>
          )}
          {content()}
        </>
      }
      footer={
        <>
          {!hasValidMustacheTemplate && (
            <AlertBanner
              className="mx-3 mb-3"
              type={AlertType.Danger}
              subtitle={
                "Invalid templating. Please remove superfluous '{' and '}' characters and use attribute selectors instead."
              }
            />
          )}
          <ResponseCardFooter
            onSave={handleSave}
            saveButtonProps={getSaveButtonState()}
            onCancel={onCancel}
          />
        </>
      }
    />
  )

  function getSaveButtonState(): IAnswerSaveButtonProps {
    if (answerType === AnswerType.Answer && !editingAnswer) {
      return { disabled: true, tooltipText: 'Please provide an answer.' }
    }
    if (!!(shouldBePersonalized && !isPersonalized)) {
      return {
        disabled: true,
        tooltipText:
          'Personalized responses must have an audience or a custom field.',
      }
    }
    if (!hasValidMustacheTemplate) {
      return {
        disabled: true,
        tooltipText:
          "Invalid templating. Please remove superfluous '{' and '}' characters and use attribute selectors instead.",
      }
    }
    if (answerType === AnswerType.Interactive && !script.value) {
      return {
        disabled: true,
        tooltipText: 'Please select an interactive response.',
      }
    }
    return { disabled: false, tooltipText: undefined }
  }
}

interface IKBResponseReadProps {
  onApprove: () => void
  onUnapprove: () => void
  onDelete: (() => void) | undefined
  answer?: string
  dialogId: string | null
  dialogName: string | null
  generative: boolean
  approved: boolean
  lastModifiedByName?: string
  lastModifiedAt?: string
  lastEmbeddedAt?: string | null
  hideApproval?: boolean
  audience?: React.ReactElement
  contactFilterName?: string
  readonly deleteButtonProps?: IDeleteButtonProps
  readonly allowPersonalization?: boolean
  readonly workflowSteps: ICampaignScriptStep[]
  readonly dragHandleProps: DraggableProvidedDragHandleProps | undefined
}
export function KBResponseRead({
  answer,
  approved,
  onUnapprove,
  onApprove,
  onDelete,
  lastModifiedByName,
  lastModifiedAt,
  lastEmbeddedAt,
  hideApproval = false,
  allowPersonalization = true,
  audience,
  contactFilterName,
  workflowSteps,
  dialogId,
  dialogName,
  generative,
  dragHandleProps,
  deleteButtonProps,
}: IKBResponseReadProps) {
  const targetAudienceInfo = allowPersonalization ? (
    <div className="py-3 px-3 position-relative">
      <div className="caption">Who can receive this response?</div>
      <p className="m-0">
        {contactFilterName || (audience ? null : 'All Contacts')}
      </p>
      <div>{audience}</div>
    </div>
  ) : null

  const content = () => {
    if (generative) {
      return (
        <div className="px-3">
          <span className="caption">Response</span>
          <ReferenceContentCard
            type="Generative"
            className="p-0"
            icon={
              <div className="firefly p-0 pl-2">
                <FireFlyIcon />
              </div>
            }
            fillIcon={false}>
            <div className="font-italic">AI Generated Response</div>
          </ReferenceContentCard>
        </div>
      )
    }
    if (!!dialogId) {
      return (
        <ScriptResponseContent
          value={
            (dialogId &&
              dialogName && { value: dialogId, label: dialogName }) ||
            undefined
          }
          name="inputName"
          label="Response"
          editing={false}>
          <div
            onClick={e => {
              e.stopPropagation()
              e.preventDefault()
            }}>
            {workflowSteps && workflowSteps.length > 0 && (
              <ScriptResultsSection
                title="Script Survey Results"
                info="If this script was also used in a campaign those results will appear here."
                dialogId={dialogId}
                downloadResults={true}
                steps={workflowSteps.map(x => ({
                  choices: x.choiceResponseCounts ?? null,
                  prompt: x.prompt,
                  personalizedPrompt: x.personalizedPrompt,
                  promptType: x.promptType,
                  id: x.id,
                  count: x.totalResponseCount || 0,
                  totalResponseCount: x.totalResponseCount || 0,
                }))}
              />
            )}
          </div>
        </ScriptResponseContent>
      )
    }
    return !isUndefined(answer) ? (
      <BasicResponseCardContentReadMode response={answer} />
    ) : null
  }

  return (
    <ResponseCard
      mode="read"
      className="hover-bg-blue-grey-050 mb-2"
      approved={approved}
      loading={false}
      onApprove={onApprove}
      onUnapprove={onUnapprove}
      lastModifiedAt={lastModifiedAt}
      lastModifiedByName={lastModifiedByName}
      lastEmbeddedAt={lastEmbeddedAt}
      hideApproval={hideApproval}
      header={
        <BasicResponseHeader
          editing={false}
          onDelete={onDelete}
          audience={{ targetAudienceInfo }}
          dragHandleProps={dragHandleProps}
          deleteButtonProps={deleteButtonProps}
        />
      }
      content={content()}
    />
  )
}

interface IAddResponseButtonProps {
  disabled: boolean
  onClick: () => void
  className?: string
  innerRef?: React.Ref<HTMLButtonElement>
}

export const AddResponseButton = ({
  onClick,
  disabled,
  className,
  innerRef,
}: IAddResponseButtonProps) => (
  <PermissionGuard permission={PERMISSIONS.UNDERSTANDING.CREATE}>
    <Button
      color="link"
      ref={innerRef}
      className={classNames(
        className,
        'p-0 mt-3 d-flex align-items-center text-decoration-none',
        { disabled }
      )}
      onClick={onClick}>
      <AHIcon className="pointer" name="add_circle_outline" />
      <span>Add Response</span>
    </Button>
  </PermissionGuard>
)
