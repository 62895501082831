interface ISpreadsheetIconProps {
  readonly height?: number | string
  readonly width?: number | string
}
export function SpreadsheetIcon({
  height = '32px',
  width = '100%',
}: ISpreadsheetIconProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 32 32"
      fill="none">
      <path
        d="M21.3337 17.056V21.3333H17.067V17.056H21.3337ZM21.3337 10.6603V14.9227H17.067V10.6603H21.3337ZM14.9337 10.6603H10.667V14.9227H14.9337V10.6603ZM14.9337 17.056H10.667V21.3333H14.9337V17.056Z"
        fill="#5F779A"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.13379 3.2C2.13379 2.35131 2.47093 1.53737 3.07105 0.937258C3.67116 0.337142 4.4851 0 5.33379 0L22.8421 0L29.8671 7.02507V28.8C29.8671 29.6487 29.53 30.4626 28.9299 31.0627C28.3298 31.6629 27.5158 32 26.6671 32H5.33379C4.4851 32 3.67116 31.6629 3.07105 31.0627C2.47093 30.4626 2.13379 29.6487 2.13379 28.8V3.2ZM23.4671 8.52693H8.53379V23.4667H23.4671V8.52693Z"
        fill="#5F779A"
      />
    </svg>
  )
}
