import * as React from 'react'
import SideEffectNode from 'components/SideEffectNode/SideEffectNode'
import {
  IExitAction,
  ICampaignScriptStep,
  ExitActionType,
  IRagtimeActionArgs,
} from 'store/campaign-scripts/reducer'
import { AHIcon } from 'components/Icons/AHIcon/AHIcon'
import { Formik, FormikProps, getIn } from 'formik'
import { Button } from 'components/Button/Button'
import * as nope from 'yup'
import { OutsideClickHandler } from 'components/OutsideClickHandler/OutsideClickHandler'
import { VerticalLine } from 'components/NodeSubtree/NodeSubtree'
import Select from 'components/Select/SelectV2'
import { LabeledTextInput } from 'components/LabeledInput/LabeledInput'
import * as api from 'api'
import { isRight } from 'fp-ts/lib/These'
import { toast } from 'mainstay-ui-kit/MainstayToast/MainstayToast'
import { Failure, isSuccess, Loading, Success, WebData } from 'store/webdata'
import { Checkbox } from 'components/Checkbox/Checkbox'
import { FancyTextArea } from 'embed/components/WebChatFancyTextArea'
import { isNil } from 'lodash'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Tooltip } from 'components/Tooltip/Tooltip'
import { KnowledgeSourceSelect } from 'page/knowledge-base/KnowledgeSource/KnowledgeSourceSelect'

// use a generative topic ID of 0 to indicate the prompt is a custom one
const CUSTOM_PROMPT_GEN_AI_TOPIC_ID = 0
interface ITopicOption {
  label: string
  value: number
  data: {
    include_scraped_content: boolean
  }
}

interface IRagtimeActionProps {
  step: ICampaignScriptStep
  action: IExitAction<IRagtimeActionArgs>
  actionIndex: number
  editable: boolean
  onCancel: () => void
  editMode: boolean
  setEditMode: (val: boolean) => void
  onUpdateNode: (
    data: Partial<ICampaignScriptStep>,
    stayInEditMode?: boolean
  ) => void
}

export const RagTimeAction = ({
  editMode,
  action,
  step,
  editable,
  setEditMode,
  onCancel,
  onUpdateNode,
  actionIndex,
}: IRagtimeActionProps) => {
  const handleDeleteAction = React.useCallback(() => {
    const newExitActions = (step.exitActions || []).filter(
      (_, i: number) => i !== actionIndex
    )
    onUpdateNode({ exitActions: newExitActions })
  }, [actionIndex, onUpdateNode, step.exitActions])

  const [topicOptions, setTopicOptions] = React.useState<
    WebData<ITopicOption[]>
  >(Loading())
  React.useEffect(() => {
    api.listGenerativeTopics().then(res => {
      if (isRight(res)) {
        setTopicOptions(
          Success(
            res.right.map(topicData => ({
              label: topicData.name,
              value: topicData.id,
              data: topicData,
            }))
          )
        )
        return
      }
      toast('Failed to fetch generative topics', { type: 'error' })
      setTopicOptions(Failure())
    })
  }, [setTopicOptions])

  const handleClick = React.useCallback(() => setEditMode(true), [setEditMode])

  const handleUpdateAction = React.useCallback(
    (updatedAction: IExitAction<IRagtimeActionArgs>) => {
      const newExitActions = step.exitActions || []
      newExitActions[actionIndex] = { ...updatedAction, newlyCreated: false }
      onUpdateNode({ exitActions: newExitActions })
    },
    [actionIndex, onUpdateNode, step.exitActions]
  )

  const label = React.useMemo(() => {
    if (!isSuccess(topicOptions)) {
      return ''
    }
    const topicName =
      action.args.genAiTopicId === CUSTOM_PROMPT_GEN_AI_TOPIC_ID
        ? 'Custom Prompt'
        : topicOptions.data.find(o => o.value === action.args.genAiTopicId)
            ?.label
    if (topicName === undefined) {
      return 'Gen-AI Discussion Topic not found. Please choose a valid topic'
    }
    return `Enter Gen-AI Discussion: ${topicName} (${action.args.maxMessages} Responses or ${action.args.maxMinutes} Minutes)`
  }, [action, topicOptions])

  if (!isSuccess(topicOptions)) {
    return null
  }

  if (editMode || action.newlyCreated) {
    return (
      <div>
        <RagtimeActionForm
          action={action}
          onDeleteAction={handleDeleteAction}
          onCancel={onCancel}
          onUpdateAction={handleUpdateAction}
          options={topicOptions.data}
        />
      </div>
    )
  } else {
    return (
      <div>
        <VerticalLine />
        <SideEffectNode
          editable={editable}
          onClick={() => {
            if (editable) {
              handleClick()
            }
          }}>
          {label}
        </SideEffectNode>
      </div>
    )
  }
}

const validationSchema = nope.object().shape({
  genAiTopicId: nope
    .number()
    .required()
    .moreThan(-1)
    .typeError('Something went wrong. Try selecting your choice again.'),
  promptOverrides: nope.object().when('genAiTopicId', {
    is: (genAiTopicId: number) =>
      genAiTopicId === CUSTOM_PROMPT_GEN_AI_TOPIC_ID,
    then: nope.object({
      customPrompt: nope.string().required('Please enter a custom prompt'),
    }),
  }),
  knowledgeSources: nope.array().of(nope.number()),
  maxMinutes: nope
    .number()
    .required()
    .moreThan(0, 'Duration must be between 1 and 1440 minutes (one day)')
    .lessThan(1441, 'Duration must be between 1 and 1440 minutes (one day)')
    .typeError(
      'Duration must be a number between 1 and 1440 minutes (one day)'
    ),
  maxMessages: nope
    .number()
    .required()
    .moreThan(0, 'Maximum responses must be between 1 and 100')
    .lessThan(101, 'Maximum responses must be between 1 and 100')
    .typeError('Maximum responses must be a number between 1 and 100'),
})

type RagTimeActionForm = IRagtimeActionArgs

interface IRagtimeActionFormProps {
  action: IExitAction<IRagtimeActionArgs>
  onCancel: () => void
  onDeleteAction: () => void
  onUpdateAction: (updatedAction: IExitAction<IRagtimeActionArgs>) => void
  options: ITopicOption[]
}

const RagtimeActionForm = ({
  action,
  onCancel,
  onUpdateAction,
  onDeleteAction,
  options,
}: IRagtimeActionFormProps) => {
  const topicOptions = [
    ...options,
    { label: 'Custom Prompt', value: CUSTOM_PROMPT_GEN_AI_TOPIC_ID },
  ]

  const showKnowledgeSources = (values: IRagtimeActionArgs) => {
    if (values.promptOverrides?.includeScrapedContent) {
      return true
    }

    const selectedTopic = options.find(
      value => value.value === values.genAiTopicId
    )

    if (selectedTopic?.data?.include_scraped_content) {
      return true
    }
  }

  return (
    <Formik<RagTimeActionForm>
      validationSchema={validationSchema}
      initialValues={{
        ...action.args,
        ...(!isNil(action.args.genAiTopicId)
          ? {}
          : { genAiTopicId: options[0].value }),
      }}
      onSubmit={(formValues: RagTimeActionForm) => {
        onUpdateAction({ name: ExitActionType.Ragtime, args: formValues })
      }}>
      {(form: FormikProps<RagTimeActionForm>) => (
        <OutsideClickHandler
          excludeClassname="modal"
          containerClassName="border rounded background-white w-500px shadow-md mt-3  max-height-700px overflow-y-auto"
          onClickOutside={() => form.handleSubmit()}>
          <div className="border rounded background-white">
            <div className="px-4 pt-4">
              <div className="d-flex justify-content-between">
                <h6>Enter Gen-AI Discussion</h6>
                <AHIcon
                  name="delete"
                  onClick={onDeleteAction}
                  className="pointer opacity-50 hover-opacity-100 hover-text-new-ui-danger"
                />
              </div>
            </div>
            <form onSubmit={form.handleSubmit}>
              <div className="px-4 py-2">
                <p className="caption mt-2 mb-1">Select a topic</p>
                <Select<{ label: string; value: number }>
                  error={!!form.errors.genAiTopicId}
                  options={topicOptions}
                  value={
                    topicOptions.find(
                      option => option.value === form.values.genAiTopicId
                    ) || topicOptions[0]
                  }
                  onChange={option => {
                    if (option && !Array.isArray(option)) {
                      form.setFieldValue('genAiTopicId', option.value)
                    }

                    if (!showKnowledgeSources(form.values)) {
                      form.setFieldValue('knowledgeSources', [])
                    }
                  }}
                />
                {form.errors.genAiTopicId && (
                  <div className="text-danger small">
                    {form.errors.genAiTopicId}
                  </div>
                )}
                {form.values.genAiTopicId === CUSTOM_PROMPT_GEN_AI_TOPIC_ID && (
                  <>
                    <div className="my-3">
                      <FancyTextArea
                        resizable={false}
                        isValid={
                          !(
                            form.touched.promptOverrides &&
                            getIn(form.errors, 'promptOverrides.customPrompt')
                          )
                        }
                        rows={4}
                        onChange={e => {
                          form.setTouched({ promptOverrides: true })
                          form.setFieldValue(
                            'promptOverrides.customPrompt',
                            e.target.value
                          )
                        }}
                        value={form.values.promptOverrides?.customPrompt}
                        placeholder="Enter custom prompt here"
                        className="text-area"
                      />
                      {form.touched.promptOverrides &&
                        // we use getIn to avoid a typing issue with Formik where form.errors.promptOverrides is typed as a string instead of an object
                        // ideally we should be able to use form.errors.promptOverrides?.customPrompt
                        typeof getIn(
                          form.errors,
                          'promptOverrides.customPrompt'
                        ) === 'string' && (
                          <div className="text-danger small">
                            {String(
                              getIn(form.errors, 'promptOverrides.customPrompt')
                            )}
                          </div>
                        )}
                    </div>
                    <p className="caption mt-3 pt-2 mb-0">Include context</p>
                    <div className="d-flex flex-wrap">
                      <Checkbox
                        type="check"
                        title="KB Responses"
                        checked={
                          form.values.promptOverrides?.includeAnswerContent
                        }
                        containerClassName="py-2 width-50"
                        onClick={() => {
                          form.setFieldValue(
                            'promptOverrides.includeAnswerContent',
                            !form.values.promptOverrides?.includeAnswerContent
                          )
                        }}
                      />
                      <Checkbox
                        type="check"
                        title="Org Attributes"
                        checked={
                          form.values.promptOverrides?.includeOrgAttributes
                        }
                        containerClassName="py-2 width-50"
                        onClick={() => {
                          form.setFieldValue(
                            'promptOverrides.includeOrgAttributes',
                            !form.values.promptOverrides?.includeOrgAttributes
                          )
                        }}
                      />
                      <div className="d-flex align-items-center width-50">
                        <Checkbox
                          type="check"
                          title="Past Messages"
                          checked={
                            form.values.promptOverrides?.includePastSmslogs
                          }
                          containerClassName="py-2"
                          onClick={() => {
                            form.setFieldValue(
                              'promptOverrides.includePastSmslogs',
                              !form.values.promptOverrides?.includePastSmslogs
                            )
                          }}
                        />
                        <Tooltip content="If checked, this will include recent messages from before this Script. If unchecked, only messages within this gen-AI discussion are considered.">
                          <div>
                            <FontAwesomeIcon
                              icon="info-circle"
                              className="ml-1"
                            />
                          </div>
                        </Tooltip>
                      </div>
                      <Checkbox
                        type="check"
                        title="Scraped Content"
                        checked={
                          form.values.promptOverrides?.includeScrapedContent
                        }
                        containerClassName="py-2 width-50"
                        onClick={() => {
                          form.setFieldValue(
                            'promptOverrides.includeScrapedContent',
                            !form.values.promptOverrides?.includeScrapedContent
                          )

                          if (form.values.knowledgeSources.length > 0) {
                            form.setFieldValue('knowledgeSources', [])
                          }
                        }}
                      />
                    </div>
                  </>
                )}
                <p className="caption mt-3 mb-1">
                  Exit when learner replies "exit", or after...
                </p>
                <div className="d-flex align-items-center mb-3">
                  <LabeledTextInput
                    value={form.values.maxMessages.toString()}
                    error={!!form.errors.maxMessages}
                    errorString={form.errors.maxMessages}
                    label="Responses"
                    type="number"
                    className="mb-0 w-50"
                    onChange={e => {
                      form.setFieldValue(
                        'maxMessages',
                        parseInt(e.target.value, 10)
                      )
                    }}
                  />
                  <p className="caption mx-2 mt-2">or</p>
                  <LabeledTextInput
                    value={form.values.maxMinutes.toString()}
                    error={!!form.errors.maxMinutes}
                    errorString={form.errors.maxMinutes}
                    label="Minutes"
                    type="number"
                    className="mb-0 w-50"
                    onChange={e => {
                      form.setFieldValue(
                        'maxMinutes',
                        parseInt(e.target.value, 10)
                      )
                    }}
                  />
                </div>

                {showKnowledgeSources(form.values) && (
                  <>
                    <p className="caption mt-3 mb-1">Knowledge Sources</p>
                    <KnowledgeSourceSelect
                      value={form.values.knowledgeSources}
                      onChange={value => {
                        form.setFieldValue('knowledgeSources', value)
                      }}
                    />
                  </>
                )}
              </div>
              <hr className="w-100 m-0 mt-3" />
              <div className="px-4 py-3">
                <Button
                  className="mr-2 btn btn-primary bg-secondary-teal"
                  disabled={Object.keys(form.errors).length > 0}
                  type="submit">
                  Save
                </Button>
                <Button
                  onClick={onCancel}
                  className="text-blue-grey-501 text-decoration-none"
                  color="link">
                  Cancel
                </Button>
              </div>
            </form>
          </div>
        </OutsideClickHandler>
      )}
    </Formik>
  )
}
