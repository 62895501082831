import * as api from 'api'
import { hasContactFields } from 'components/EditableOutgoingNode/EditableOutgoingNode'
import { AHIcon } from 'components/Icons/AHIcon/AHIcon'

export function ReplyDescription({
  message,
  generatedIdk,
}: {
  readonly message: api.ExpandedMessageType
  readonly generatedIdk: boolean
}) {
  if (message.origin.kind !== 'understanding') {
    return null
  }

  if (message.origin.fallback && generatedIdk) {
    return <div className="mt-2 mx-3 fs-13px">Returned a default response</div>
  }

  return (
    <>
      <FuzzyQuestionReply message={message} />
      {message.origin.generativeAnswer && (
        <div className="mx-4 mt-2 fs-13px">Returned a generated response</div>
      )}
    </>
  )
}

interface IFuzzyQuestionReplyProps {
  readonly message: api.ExpandedMessageType
}

export function FuzzyQuestionReply({ message }: IFuzzyQuestionReplyProps) {
  if (message.origin.kind !== 'understanding') {
    return null
  }
  if (!message.fuzzyQuestion) {
    return null
  }

  const messageLabel =
    message.matchType === 'textExpression'
      ? 'Text Expression'
      : 'Fuzzy Question'

  const isPersonalized =
    hasContactFields(message.messageTemplate) || message.contactFilter
  const contactFilterName = message.contactFilter?.name
  return (
    <div className="mt-1 mx-1 fs-13px px-3 py-2">
      <p className="fs-13px">
        The Bot matched to this <strong>{messageLabel}:</strong>
      </p>
      <div className="d-flex align-items-baseline">
        <div className="mx-2 mt-auto">
          <AHIcon
            name="contacts-inverse"
            className="pointer text-mainstay-dark-blue-65 "
          />
        </div>
        <div className="bg-white border-radius-md border rounded border-bottom-left-radius-0 px-0 d-flex align-items-center w-100">
          <h5 className=" m-2">"{message.fuzzyQuestion}"</h5>
        </div>
      </div>
      {isPersonalized && (
        <p className="mt-3 mx-1 mb-0 fs-13px">
          ...then returned this <strong>Personalized Response</strong>{' '}
          {contactFilterName && (
            <>
              for <strong>{contactFilterName}</strong>
            </>
          )}
          :
        </p>
      )}
    </div>
  )
}

export function RagtimeReply({ message }: IFuzzyQuestionReplyProps) {
  const { ragtimeInfo } = message
  if (!ragtimeInfo) {
    return null
  }
  return (
    <div className="mt-1 mx-1 fs-13px px-3 py-2">
      <p className="fs-13px mb-0">
        The response was generated from this Discussion topic:
      </p>
      <strong>{ragtimeInfo.generativeTopicName}</strong>
    </div>
  )
}
