import { UnderstandingApprovalMenuV2 } from 'components/UnderstandingApprovalMenu/UnderstandingApprovalMenu'
import React from 'react'
import { Card } from 'reactstrap'
import {
  LastEditedBy,
  LastEmbedded,
} from 'components/KnowledgeSeedEditPanel/KnowledgeSeedEditPanel'
import classNames from 'classnames'
import AdmithubOnly from 'components/AdmithubOnly/AdmithubOnly'
import PermissionGuard from 'util/permissions/PermissionGuard'
import { PERMISSIONS } from 'util/permissions/permissions'

export interface IResponseCardProps {
  mode: 'read' | 'write'
  approved: boolean
  hideApproval?: boolean
  onApprove: () => void
  onUnapprove: () => void
  loading: boolean
  header?: React.ReactElement
  audienceSelector?: React.ReactElement
  footer?: React.ReactElement
  content: React.ReactElement | null
  lastModifiedByName?: string
  lastModifiedAt?: string | null
  lastEmbeddedAt?: string | null
  className?: string
}

export const ResponseCard: React.FC<IResponseCardProps> = props => {
  return (
    <Card className={classNames('w-100', props.className)}>
      <PermissionGuard permission={PERMISSIONS.UNDERSTANDING.EDIT}>
        {props.header}
        <>
          {props.content}
          <div
            className={classNames(
              'd-flex justify-content-between align-items-center px-3 py-3'
            )}>
            <div>
              {!props.hideApproval && (
                <UnderstandingApprovalMenuV2
                  loading={props.loading}
                  approved={props.approved}
                  onApprove={props.onApprove}
                  onUnapprove={props.onUnapprove}
                />
              )}
            </div>

            <div className="d-flex flex-column">
              <LastEditedBy
                date={props.lastModifiedAt}
                name={props.lastModifiedByName}
              />
              <AdmithubOnly>
                <LastEmbedded date={props.lastEmbeddedAt} />
              </AdmithubOnly>
            </div>
          </div>
        </>
      </PermissionGuard>
      {props.footer}
      {props.children}
    </Card>
  )
}
