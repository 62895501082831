import axios, { CancelTokenSource } from 'axios'
import { useState, useRef, useEffect, useCallback } from 'react'
import { generativeTextService } from 'api'
import { isRight } from 'fp-ts/lib/These'
import { CenteredLoader } from 'components/Loader/Loader'
import AutoSuggest from 'components/AutoSuggest/AutoSuggest'
import { isArray } from 'lodash'

export const KnowledgeSourceSelect = ({
  onChange,
  value,
}: {
  onChange: (selectedSources: number[]) => void
  value: number[]
}) => {
  const cancelTokeHandlenRef = useRef<CancelTokenSource | null>(null)
  const [sourceOptions, setSourceOptions] = useState<
    { value: string; label: string }[]
  >([])
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(false)

  const fetchKnowledgeSources = useCallback(async () => {
    setLoading(true)
    if (cancelTokeHandlenRef.current) {
      cancelTokeHandlenRef.current.cancel()
    }
    cancelTokeHandlenRef.current = axios.CancelToken.source()

    const response = await generativeTextService.scrape.knowledgeSources.list({
      cancelToken: cancelTokeHandlenRef.current.token,
      statuses: ['SUCCESS'],
    })

    if (isRight(response)) {
      setSourceOptions(
        response.right.results.map(source => ({
          value: String(source.id),
          label: source.title,
        }))
      )
      setLoading(false)
      return
    }

    if (
      response.left.kind === 'cancel' ||
      (response.left.kind === 'http' &&
        response.left.http.code === 'ERR_CANCELED')
    ) {
      return
    }
    setLoading(false)
    setError(true)
  }, [cancelTokeHandlenRef, setSourceOptions])

  useEffect(() => {
    fetchKnowledgeSources()
  }, [fetchKnowledgeSources])

  if (loading) {
    return (
      <div className="d-flex flex-row align-center height-50px">
        <CenteredLoader className="w-100 " />
      </div>
    )
  }
  if (error) {
    return (
      <div className="d-flex flex-row align-center height-250px">
        Failed to load the knowledge sources
      </div>
    )
  }

  return (
    <div>
      <AutoSuggest<number>
        isValid={true}
        options={sourceOptions}
        value={value.map(String)}
        className="h-100"
        menuPlacement="top"
        loading={loading}
        placeholder="Add Knowledge Sources"
        multi={true}
        onChange={e => {
          if (isArray(e.target.value)) {
            onChange(e.target.value.map(Number))
          }
        }}
      />
    </div>
  )
}
