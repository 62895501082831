import React from 'react'
import { useForm, Controller } from 'react-hook-form'
import { useSelector, useDispatch, useCurrentInstitution } from 'util/hooks'
import {
  commandsSelectors,
  commandsThunks,
  HashtagCommand,
} from 'store/commands/commands-store'
import Loader from 'components/Loader/Loader'
import { Button } from 'components/Button/Button'
import {
  FormGroup,
  FormFeedback,
  Label,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
} from 'reactstrap'
import { ErrorMessage } from '@hookform/error-message'
import Card from 'reactstrap/lib/Card'
import { AsyncCustomScriptSearch } from 'components/AsyncCustomScriptSearch/AsyncCustomScriptSearch'
import { IAutoSuggestOption } from 'components/AutoSuggest/AutoSuggest'
import { IDialogForUnknownContact } from 'api/response'
import { ToggleSwitchV2 } from 'components/ToggleSwitch/ToggleSwitch'
import { push } from 'connected-react-router'
import classnames from 'classnames'
import { TransportId, Transports, Channel, ChannelEnum } from 'store/transport'
import { assertNever } from 'util/exhaustiveness'
import { toast } from 'mainstay-ui-kit/MainstayToast/MainstayToast'
import PermissionGuard from 'util/permissions/PermissionGuard'
import { PERMISSIONS } from 'util/permissions/permissions'

type CommandCreateEditFormNakedProps = {
  id?: React.ReactText
  onSubmit: (values: CommandCreateEditFormValues) => void
  fullPageForm?: boolean
}

export type CommandCreateEditFormValues = {
  command_text: string
  description: string
  enabled: boolean
} & {
  [key in TransportId]: IAutoSuggestOption<IDialogForUnknownContact> | undefined
}

type EnabledDialogs = {
  [key in TransportId]: { value: string; label: string } | undefined
}

function getEnabledDialogs(
  command: HashtagCommand | undefined
): EnabledDialogs {
  const data: EnabledDialogs = {
    web: undefined,
    twilio: undefined,
    slack: undefined,
    facebook: undefined,
  }
  for (const dialog of command?.dialogs ?? []) {
    switch (dialog.transport) {
      case 'web':
      case 'facebook':
      case 'slack':
      case 'twilio':
        if (data[dialog.transport] == null) {
          data[dialog.transport] = {
            value: dialog.dialog_id,
            label: dialog.label,
          }
        }
      case 'unknown':
        break
      default:
        assertNever(dialog.transport)
    }
  }
  return data
}

function getTransportName(transport: TransportId): string {
  switch (transport) {
    case 'twilio':
      return 'SMS'
    case 'facebook':
      return 'Facebook'
    case 'slack':
      return 'Slack'
    case 'web':
      return 'Webchat'
  }
}

function channelToTransport(channel: Channel): TransportId {
  switch (channel) {
    case 'facebook':
      return 'facebook'
    case 'sms':
      return 'twilio'
    case 'web_bot':
      return 'web'
    case 'slack':
      return 'slack'
  }
}

export const CommandCreateEditFormNaked = ({
  id,
  onSubmit,
  fullPageForm,
}: CommandCreateEditFormNakedProps) => {
  const status = useSelector(state => state.commands.retrieving)
  const command = useSelector(state =>
    commandsSelectors.selectById(state, id || -1)
  )
  const institution = useCurrentInstitution()
  const dispatch = useDispatch()

  const transportsWithCommandDialog = new Set(
    [
      ...institution.channels,
      // display command dialog picker for webchat all the time to allow testing through test the bot
      ChannelEnum.web_bot,
    ].map((c: Channel) => channelToTransport(c))
  )

  const { handleSubmit, register, errors, control } = useForm<
    CommandCreateEditFormValues
  >({
    mode: 'onChange',
    defaultValues: {
      enabled: command?.enabled ?? true,
      command_text: command?.words[0]?.command_text.replace('#', ''),
      description: command?.description,
      ...getEnabledDialogs(command),
    },
  })
  const submitHandler = handleSubmit(formValues => {
    onSubmit(fullPageForm ? formValues : { ...formValues, enabled: true })
  })
  React.useEffect(() => {
    if (!command && status !== 'pending' && id) {
      dispatch(
        commandsThunks.retrieveCommand({
          id: typeof id === 'number' ? id : parseInt(id, 10),
        })
      )
    }
  }, [command, dispatch, id, status])
  if (status === 'pending') {
    return <Loader />
  }
  return (
    <div className="w-100 position-relative">
      <div className="px-4">
        <h3 className="mt-2">#Command Editor</h3>
        <p className="text-muted">
          You can set custom hashtag triggers that contacts can use to trigger a
          script of your choosing (e.g. "#mycustomcommand").
        </p>
        <hr />
      </div>
      <form
        onBlur={() => {
          if (id) {
            submitHandler()
          }
        }}
        onSubmit={e => {
          e.preventDefault()
          submitHandler(e)
        }}>
        <div className="px-4 pb-5">
          <FormGroup className={classnames(fullPageForm ? 'mt-4' : 'mt-2')}>
            <Label for="command-word">
              <h4 className="m-0">Command</h4>
            </Label>
            <div className={classnames('d-flex align-items-center')}>
              <InputGroup className="w-50">
                <InputGroupAddon addonType="prepend">
                  <InputGroupText className="text-mainstay-dark-blue">
                    #
                  </InputGroupText>
                </InputGroupAddon>
                <Input
                  invalid={!!errors && !!errors.command_text}
                  id="command-word"
                  name="command_text"
                  innerRef={register({
                    required: 'Command word is a required field.',
                    pattern: {
                      message:
                        'Command can only consist of lowercase letters, numbers, hyphens and underscores.',
                      value: /^[a-z0-9_-]+$/,
                    },
                    minLength: {
                      message: 'Must be at least 3 characters.',
                      value: 3,
                    },
                    maxLength: {
                      message: 'Cant be more than 20 characters.',
                      value: 20,
                    },
                  })}
                />
              </InputGroup>
              {fullPageForm && (
                <Label
                  for="enabled"
                  className="d-flex align-items-center ml-3 mt-2">
                  <h5 className="m-0">Enabled? </h5>
                  <Controller
                    control={control}
                    render={props => {
                      return (
                        <ToggleSwitchV2
                          size="sm"
                          className="ml-3"
                          onChange={_e => {
                            props.onChange(!props.value)
                            submitHandler()
                          }}
                          checked={!!props.value}
                        />
                      )
                    }}
                    name="enabled"
                  />
                </Label>
              )}
            </div>
            <ErrorMessage
              errors={errors}
              name="command_text"
              render={props => (
                <FormFeedback className="d-block">{props.message}</FormFeedback>
              )}
            />
          </FormGroup>
          <FormGroup className={classnames(fullPageForm ? 'mt-4' : 'mt-2')}>
            <Label for="description">
              <h4 className="m-0">Description</h4>
            </Label>
            <Input
              invalid={!!errors && !!errors.command_text}
              type="textarea"
              id="description"
              name="description"
              className="pr-3"
              innerRef={register({
                required: 'Description is a required field.',
              })}
            />
            <ErrorMessage
              errors={errors}
              name="description"
              render={props => (
                <FormFeedback className="d-block">{props.message}</FormFeedback>
              )}
            />
          </FormGroup>
          <FormGroup className={classnames(fullPageForm ? 'mt-4' : 'mt-2')}>
            <Label for="sms">
              <h4 className="m-0">Response</h4>
            </Label>
            {Transports.filter(x => transportsWithCommandDialog.has(x)).map(
              transport => {
                return (
                  <Card className="p-3 mb-3" key={transport}>
                    <div className="d-flex justify-content-between">
                      <h5>{getTransportName(transport)} Contacts</h5>
                    </div>
                    <FormGroup className="mb-0">
                      <Label className="text-muted small mt-2" for="sms">
                        Script
                      </Label>
                      <Controller
                        name={transport}
                        control={control}
                        rules={{ required: 'Please select a dialog' }}
                        render={props => {
                          return (
                            <AsyncCustomScriptSearch
                              className="mb-0"
                              // tslint:disable-next-line: no-unsafe-any
                              value={props.value}
                              name={transport}
                              touched={true}
                              onChange={e => {
                                props.onChange(e.target.data)
                                submitHandler()
                              }}
                              isValid={!errors?.[transport]}
                            />
                          )
                        }}
                      />
                      <ErrorMessage
                        errors={errors}
                        name={transport}
                        render={props => (
                          <FormFeedback className="d-block">
                            {props.message}
                          </FormFeedback>
                        )}
                      />
                    </FormGroup>
                  </Card>
                )
              }
            )}
          </FormGroup>
        </div>
        {!id && (
          <div
            className="d-flex position-fixed justify-content-around bg-white shadow-top py-2"
            style={{
              bottom: 0,
              width: fullPageForm ? '600px' : '498px',
            }}>
            <Button color="primary" type="submit">
              Create
            </Button>
          </div>
        )}
      </form>
    </div>
  )
}

type CommandCreateEditFormProps = {
  id?: React.ReactText
}

// Wraps the raw form to handle submission (some of which is context-dependent such as page routing)
const CommandCreateEditForm = (props: CommandCreateEditFormProps) => {
  const dispatch = useDispatch()

  const handleSubmit = React.useCallback(
    (formValues: CommandCreateEditFormValues) => {
      const request = mapFormDataToRequest(formValues)
      if (props.id === undefined) {
        dispatch(commandsThunks.createCommand(request)).then(x => {
          if (commandsThunks.createCommand.fulfilled.match(x)) {
            dispatch(push(`/commands/`))
          }
          if (commandsThunks.createCommand.rejected.match(x)) {
            toast('Error encountered while saving command', {
              type: 'error',
            })
          }
        })
      } else {
        dispatch(
          commandsThunks.updateCommand({
            ...request,
            id:
              typeof props.id === 'number' ? props.id : parseInt(props.id, 10),
          })
        ).then(x => {
          if (commandsThunks.updateCommand.rejected.match(x)) {
            toast('Error encountered while saving command', { type: 'error' })
          }
        })
      }
    },
    [dispatch, props.id]
  )
  return (
    <PermissionGuard permission={PERMISSIONS.UNDERSTANDING.EDIT}>
      <CommandCreateEditFormNaked
        id={props.id}
        onSubmit={handleSubmit}
        fullPageForm
      />
    </PermissionGuard>
  )
}

export const mapFormDataToRequest = (
  formValues: CommandCreateEditFormValues
) => {
  const dialogs: { [key in TransportId]: string | undefined } = {
    twilio: formValues.twilio?.value,
    web: formValues.web?.value,
    facebook: formValues.facebook?.value,
    slack: formValues.slack?.value,
  }
  return {
    name: formValues.command_text,
    description: formValues.description,
    words: [`#${formValues.command_text}`],
    dialogs,
    enabled: formValues.enabled || false,
  }
}

export default CommandCreateEditForm
