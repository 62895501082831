import classNames from 'classnames'
import * as React from 'react'
import {
  WebData,
  Loading,
  Success,
  isSuccess,
  isLoading,
  Failure,
  isInitial,
} from 'store/webdata'
import { isRight } from 'fp-ts/lib/These'
import { toast } from 'mainstay-ui-kit/MainstayToast/MainstayToast'
import { Modal } from 'reactstrap'
import ModalBody from 'reactstrap/lib/ModalBody'
import 'page/knowledge-base/AttributesPageContainer.scss'
import Dropzone from 'react-dropzone'
import { IDropZoneChildrenProps } from 'components/CSVUploadInput/CSVUploadInput'
import { timeout } from 'util/timeout'
import { Either, isLeft } from 'fp-ts/lib/Either'
import Loader from 'components/Loader/Loader'
import { SpreadsheetIcon } from 'components/Icons/SpreadsheetIcon/SpreadsheetIcon'
import { AHIcon } from 'components/Icons/AHIcon/AHIcon'
import * as settings from 'const/settings'
import { Http2ErrorUnion, http2DetailOrDefault } from 'api/http'
import { SimpleModalHeader } from 'components/SimpleModalHeader/SimpleModalHeader'
import { SimpleModalFooter } from 'components/SimpleModalFooter/SimpleModalFooter'

export interface IUploadResult {
  countFullErrors: number
  countPartialErrors: number
  fileUrl: string
}

interface IUploaderContentProps {
  onClickDownload: () => void
  isDownloadDisabled: boolean
  errorMessage: string | null
  prefixText: string
  linkText: string
}

export const UploaderContent = ({
  onClickDownload,
  isDownloadDisabled,
  errorMessage,
  prefixText,
  linkText,
}: IUploaderContentProps) => (
  <div className="margin-bottom-2_5rem">
    {errorMessage && (
      <div className="text-mainstay-error-red mb-1">{errorMessage}</div>
    )}
    <span>{prefixText} </span>
    {isDownloadDisabled ? (
      <span>{linkText}</span>
    ) : (
      <span className="link-to-download" onClick={onClickDownload}>
        {linkText}
      </span>
    )}

    <span>.</span>
  </div>
)

const ReadyContent = ({
  focusFileInput,
  title,
}: {
  focusFileInput: (e: React.MouseEvent) => void
  title: string
}) => (
  <div className="text-mainstay-dark-blue-80 instruction d-flex flex-column align-items-center justify-content-center">
    <AHIcon
      name="file_upload"
      className="text-mainstay-dark-blue-80 mb-2 icon-font-size"
    />
    <div className="d-flex flex-column align-items-center justify-content-center w-100">
      <div className="header text-mainstay-dark-blue-80 mb-2">{title}</div>
      <div>
        <span>Drag and drop or </span>
        <span className="link-to-download" onClick={focusFileInput}>
          choose a file from your computer
        </span>
      </div>
    </div>
  </div>
)

const DragActiveContent = () => (
  <div className="d-flex flex-column align-items-center justify-content-center">
    <SpreadsheetIcon width="32px" height="32px" />
    <div className="header text-mainstay-dark-blue-80 mt-2">
      Drop files here
    </div>
  </div>
)

const UploadInProgressContent = ({ fileName }: { fileName: string }) => (
  <div className="d-flex flex-column align-items-center justify-content-center">
    <Loader />
    <TruncatedFileName fileName={fileName} />
  </div>
)

const FilePreview = ({
  fileName,
  children,
}: {
  fileName: string
  children: React.ReactNode
}) => (
  <div className="d-flex flex-column align-items-center justify-content-center">
    <TruncatedFileName fileName={fileName} />
    {children}
  </div>
)

const TruncatedFileName = ({ fileName }: { fileName: string }) => {
  const offset = 15
  return (
    <div className="d-flex text-mainstay-dark-blue-80 mt-2 pb-3 max-width-300">
      <span className="d-inline-block text-ellipsis">
        {fileName.slice(0, fileName.length - offset)}
      </span>
      <span className="d-inline-block no-wrap">
        {fileName.slice(-1 * offset)}
      </span>
    </div>
  )
}

function validateFile(file: File, acceptedFileTypes: string) {
  // account for csv file types from both Mac/Linux and Windows
  const isCsvFile =
    file.type === 'text/csv' ||
    (file.type === '' && file.name.endsWith('.csv')) ||
    (file.type === 'application/vnd.ms-excel' && file.name.endsWith('.csv'))

  const allowedExtensions = acceptedFileTypes.split(',').map(ext =>
    ext
      .trim()
      .toLowerCase()
      .replace(/^\./, '')
  )

  const fileExtension =
    file.name
      .split('.')
      .pop()
      ?.toLowerCase() || ''

  const isAcceptedFile = allowedExtensions.includes(fileExtension) || isCsvFile

  if (!isAcceptedFile) {
    toast(`Only the following file types are allowed: ${acceptedFileTypes}`, {
      type: 'error',
    })
    return false
  }

  if (file.size === 0) {
    toast(`Upload failed, please select a non-empty file`, { type: 'error' })
    return false
  }
  return true
}

export const UploadFileDropZone = <T,>({
  fileName,
  readyContentTitle,
  uploadResult,
  setFileName,
  setFile,
  acceptedFileTypes = '.csv',
  stretchContainer = false,
  replaceContentTitle,
}: {
  readyContentTitle: string
  fileName: string
  uploadResult: WebData<T>
  setFileName: React.Dispatch<React.SetStateAction<string>>
  setFile: React.Dispatch<React.SetStateAction<File | undefined>>
  acceptedFileTypes?: string
  stretchContainer?: boolean
  replaceContentTitle?: string
}) => {
  const inputRef: React.MutableRefObject<null | HTMLInputElement> = React.useRef(
    null
  )

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files == null || e.target.files.length === 0) {
      return
    }
    const file = e.target.files[0]
    if (validateFile(file, acceptedFileTypes)) {
      setFileName(file.name)
      setFile(file)
    }
  }

  const focusFileInput = (e: React.MouseEvent) => {
    e.stopPropagation()

    const inputEl = inputRef.current

    if (inputEl != null && e.target !== inputEl) {
      inputEl.click()
    }
  }

  const handleDrop = (files: Array<File>) => {
    // we have `multiple` set to false, so we know this array will always be a single File
    const file = files[0]
    if (validateFile(file, acceptedFileTypes)) {
      setFileName(file.name)
      setFile(file)
    }
  }

  return (
    <Dropzone
      onDropAccepted={handleDrop}
      activeClassName=""
      acceptClassName=""
      acceptStyle={{}}
      activeStyle={{}}
      style={{}}
      className="h-100"
      multiple={false}
      disableClick>
      {({ isDragActive }: IDropZoneChildrenProps) => {
        const containerClass = classNames('dropzone', 'margin-bottom-2_5rem', {
          'dropzone-inactive': isInitial(uploadResult),
          'dropzone-active': isLoading(uploadResult),
          'w-100': stretchContainer,
        })
        return (
          <div className={containerClass}>
            <div className="d-flex align-items-center justify-content-center flex-column position-relative margin-y-2_25rem margin-x-2rem">
              {isDragActive ? (
                <DragActiveContent />
              ) : isLoading(uploadResult) && fileName ? (
                <UploadInProgressContent fileName={fileName} />
              ) : fileName && replaceContentTitle ? (
                <FilePreview fileName={fileName}>
                  <ReadyContent
                    title={replaceContentTitle}
                    focusFileInput={focusFileInput}
                  />
                </FilePreview>
              ) : (
                <ReadyContent
                  title={readyContentTitle}
                  focusFileInput={focusFileInput}
                />
              )}
              <input
                ref={inputRef}
                onInput={handleFileChange}
                type="file"
                accept={acceptedFileTypes}
                className="sr-only"
              />
              {!isDragActive && (
                <div className="header-color max-file-size">{`Max. file size: ${settings.MAX_CSV_SIZE}`}</div>
              )}
            </div>
          </div>
        )
      }}
    </Dropzone>
  )
}

export interface IUploadRequestData {
  file: File
  fileName: string
}

interface IUploadFileModalProps<T> {
  open: boolean
  title: string
  uploadPrefixText: string
  uploadLinkText: string
  readyContentTitle: string
  errorInstructions: string
  includeFooter?: boolean
  isDownloadDisabled: boolean
  requestUpload: (
    formFields: IUploadRequestData
  ) => Promise<
    Either<
      Http2ErrorUnion,
      {
        task_id: string
        file_name: string
        upload_id?: string | number
      }
    >
  >
  pollUpload({
    taskId,
    fileName,
    uploadId,
  }: {
    taskId: string
    fileName: string
    uploadId?: number | string
  }): Promise<
    Either<
      Http2ErrorUnion,
      {
        task_state: string
        result: T | null
      }
    >
  >
  onToggle: () => void
  handleDownload: () => void
  onError: () => void
  ResultsNode: ({ uploadResult }: { uploadResult: T }) => JSX.Element
}

export const UploadFileModalContainer = ({
  open,
  includeFooter,
  modalBody,
  title,
  submitText,
  onCloseModal,
  onSubmit,
}: {
  open: boolean
  includeFooter?: boolean
  modalBody: React.ReactNode
  title: string
  submitText?: string
  onCloseModal: () => void
  onSubmit?: () => void
}) => {
  return (
    <Modal
      isOpen={open}
      size="lg"
      toggle={onCloseModal}
      className="min-width-500">
      <SimpleModalHeader text={title} onClose={onCloseModal} />
      <ModalBody className="min-h-450 min-width-500">{modalBody}</ModalBody>
      {includeFooter && (
        <SimpleModalFooter
          cancelText="Close"
          onCancel={onCloseModal}
          submitText={submitText}
          onSubmit={onSubmit}
        />
      )}
    </Modal>
  )
}

export function UploadFileModal<T>({
  open,
  title,
  uploadPrefixText,
  uploadLinkText,
  readyContentTitle,
  errorInstructions,
  isDownloadDisabled,
  includeFooter,
  requestUpload,
  pollUpload,
  onToggle,
  handleDownload,
  onError,
  ResultsNode,
}: IUploadFileModalProps<T>) {
  const [importErrorMessage, setImportErrorMessage] = React.useState<
    string | null
  >(null)

  const [uploadResult, setUploadResult] = React.useState<WebData<T>>(undefined)

  const [fileName, setFileName] = React.useState('')
  const [file, setFile] = React.useState<File | undefined>(undefined)

  React.useEffect(() => {
    if (!file) {
      return
    }
    requestUpload({ file, fileName })
      .then(res => {
        setUploadResult(Loading())
        if (isRight(res)) {
          return res
        } else if (isLeft(res)) {
          setImportErrorMessage(
            http2DetailOrDefault(
              res.left,
              `Invalid column headers. ${errorInstructions}`
            )
          )
          throw new Error()
        }
      })
      .then(async res => {
        let polling = true
        while (polling) {
          if (res?.right.task_id && res?.right.file_name) {
            setImportErrorMessage(null)
            const result = await pollUpload({
              taskId: res?.right.task_id,
              fileName: res?.right.file_name,
              uploadId: res?.right.upload_id,
            })
            if (isRight(result)) {
              if (result.right.task_state === 'PENDING') {
                await timeout(1000)
              } else {
                polling = false
                if (
                  result.right.task_state === 'SUCCESS' &&
                  result.right.result
                ) {
                  setUploadResult(Success(result.right.result))
                } else {
                  setUploadResult(Failure(undefined))
                  throw new Error()
                }
              }
            } else {
              polling = false
              setUploadResult(Failure(undefined))
              throw new Error()
            }
          }
        }
      })
      .catch(_e => {
        setUploadResult(undefined)
        onError()
      })
  }, [
    file,
    fileName,
    setUploadResult,
    pollUpload,
    requestUpload,
    onError,
    errorInstructions,
  ])

  const onCloseModal = () => {
    onToggle()
    setUploadResult(undefined)
    setImportErrorMessage(null)
    setFile(undefined)
    setFileName('')
  }

  if (!isSuccess(uploadResult)) {
    return (
      <UploadFileModalContainer
        title={title}
        open={open}
        onCloseModal={onCloseModal}
        modalBody={
          <div className="d-flex flex-column align-items-center justify-content-center">
            <UploaderContent
              prefixText={uploadPrefixText}
              linkText={uploadLinkText}
              onClickDownload={handleDownload}
              isDownloadDisabled={isDownloadDisabled}
              errorMessage={importErrorMessage}
            />
            <UploadFileDropZone
              fileName={fileName}
              setFile={setFile}
              setFileName={setFileName}
              readyContentTitle={readyContentTitle}
              uploadResult={uploadResult}
            />
          </div>
        }
      />
    )
  }

  return (
    <UploadFileModalContainer
      title={title}
      open={open}
      onCloseModal={onCloseModal}
      includeFooter={includeFooter}
      modalBody={<ResultsNode uploadResult={uploadResult.data} />}
    />
  )
}
